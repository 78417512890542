<template>
  <back-section :menu="nama" />
  <list-data-cuti-bersama />
</template>
<script>
import ListDataCutiBersama from "@/components/ListDataCutiBersama.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Cuti Bersama",
    };
  },
  components: { ListDataCutiBersama, BackSection },
};
</script>
