<template>
  <div>
    <button
      @click.prevent="downloadPDF()"
      class="btn btn-success btn-sm sticky-top position-absolute"
      style="top: 65px"
    >
      Download Formulir
    </button>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      :paginate-elements-by-height="1400"
      :filename="namafile + nama"
      :pdf-quality="2"
      :pdf-margin="10"
      :manual-pagination="false"
      enableLinks="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="800px"
      @progress="onProgress($event)"
      @hasStartedGeneration="hasStartedGeneration()"
      @hasGenerated="hasGenerated($event)"
      @beforeDownload="beforeDownload($event)"
      @hasDownloaded="attemptPrint($event)"
      ref="html2Pdf"
    >
      <template v-slot:pdf-content>
        <content-pdf
          :hakCuti="hakCuti"
          :nama="nama"
          :sisaCuti="sisaCuti"
          :tanggalBerakhir="tanggalBerakhir"
          :tanggalMulai="tanggalMulai"
          :tanggalSaatIni="tanggalSaatIni"
          :kembaliTanggal="kembaliTanggal"
          :keterangan="keterangan"
          :kontak="kontak"
        />
      </template>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from "@seinist/vue3-html2pdf";
import ContentPdf from "@/components/ContentPdf.vue";

export default {
  props: {
    nama: {
      type: String,
      default: "Memo Form Cuti",
    },
    tanggalSaatIni: {
      type: String,
      default: "00 xxx 0000",
    },
    tanggalMulai: {
      type: String,
      default: "00 xxx 0000",
    },
    tanggalBerakhir: {
      type: String,
      default: "00 xxx 0000",
    },
    kembaliTanggal: {
      type: String,
      default: "00 xxx 0000",
    },
    kontak: {
      type: String,
      default: "00000000000",
    },
    sisaCuti: {
      type: String,
      default: "0",
    },
    hakCuti: {
      type: String,
      default: "12",
    },
    keterangan: {
      type: String,
      default: "12",
    },
  },
  data() {
    return {
      namafile: `Formulir Cuti `,
    };
  },
  beforeMount() {
    var vm = this;
    vm.get("img");
  },
  methods: {
    onProgress(event) {
      console.log(`Processed: ${event} / 100`);
    },
    hasGenerated(event) {
      console.log(event);
      //   alert("PDF generated successfully!");
    },
    downloadPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    attemptPrint(event) {
      console.log(event);
      // let url = URL.createObjectURL(blob);
      // window.open(url); //opens the pdf in a new tab
    },
    beforeDownload(event) {
      console.log(event);
    },
    windowsprints() {},
    get(key) {
      localStorage.getItem(key);
    },
    set(key, value) {
      try {
        localStorage.setItem(key, value);
      } catch (e) {
        console.log(`Storage failed: ${e}`);
      }
    },
  },
  components: {
    VueHtml2pdf,
    ContentPdf,
  },
};
</script>
