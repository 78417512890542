<template>
  <div class="form-cuti">
    <form @submit.prevent="submitKeluar" class="row g-3">
      <div class="col-12">
        <label>Kegiatan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="kegiatan"
          placeholder="tuliskan kegiatan yang dilakukan hari ini."
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Kirim</button>
        <a @click="$router.go(-1)" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
import moment from "moment";
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data: function () {
    return {
      kegiatan: "",
      id: parseInt(localStorage.getItem("id")),
    };
  },
  methods: {
    async submitKeluar() {
      if (this.kegiatan.trim() !== "") {
        // const pesan =
        //   "*Absensi Harian* :" +
        //   "\n" +
        //   "Nama : " +
        //   localStorage.getItem("nama") +
        //   "\n" +
        //   "Tanggal Absen : 18-09-2023" +
        //   "\n" +
        //   "Site : Erendi Digital Labs" +
        //   "\n" +
        //   "Jam Masuk : 08:40" +
        //   "\n" +
        //   "Jam Pulang : 19:00" +
        //   "\n" +
        //   "Kegiatan : Testing Absen Keluar";
        // try {
        //   axios
        //     .post(this.UrlApi + "testwa", {
        //       url: "group/sendmessage/HRD info",
        //       message: pesan,
        //     })
        //     .then(() => {
        //       // console.log(res);
        //     });
        // } catch (error) {
        //   console.log(error);
        // }

        try {
          if (localStorage.getItem("masuk") !== null) {
            let Dt = new Date();
            let Hrs = Dt.getHours();
            Hrs = ("0" + Hrs).slice(-2);
            let Mnt = Dt.getMinutes();
            Mnt = ("0" + Mnt).slice(-2);
            let Tm = (Hrs + ":" + Mnt).toString();
            axios
              .post(
                this.UrlApi + "absenkeluar",
                {
                  id: JSON.parse(localStorage.getItem("masuk"))[0]["absen"],
                  jam_pulang: Tm,
                  kegiatan: this.kegiatan,
                  id_pegawai: this.id,
                },
                { headers: headers }
              )
              .then((res) => {
                if (res.data.success && res.data.success !== null) {
                  const pesan =
                    "*Absensi Harian* :" +
                    "\n" +
                    "Nama : " +
                    localStorage.getItem("nama") +
                    "\n" +
                    "Tanggal Absen : " +
                    this.formatDate(
                      JSON.parse(localStorage.getItem("masuk"))[0]["tanggal"]
                    ) +
                    "\n" +
                    "Site : " +
                    res.data.data[0].note +
                    "\n" +
                    "Jam Masuk : " +
                    res.data.data[0].jam_masuk +
                    "\n" +
                    "Jam Pulang : " +
                    res.data.data[0].jam_pulang +
                    "\n" +
                    "Kegiatan : " +
                    res.data.data[0].kegiatan;
                  try {
                    axios
                      .post(this.UrlApi + "postwa", {
                        url: "group/sendmessage/HRD info",
                        message: pesan,
                      })
                      .then(() => {
                        // console.log(res);
                      });
                  } catch (error) {
                    console.log(error);
                  }
                  Swal.fire({
                    icon: "success",
                    title: "Berhasil!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  localStorage.removeItem("masuk");
                  this.$router.push("/list-absen");
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Gagal!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                if (err.response) {
                  console.log("error response");
                } else if (err.request) {
                  console.log("error request");
                } else {
                  console.log("error");
                }
              });
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: "Anda Belum Melakukan Absen Masuk!",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        Swal.fire({
          icon: "warning",
          // title: "Himbauan!",
          text: "Silahkan ulangi isi data kegiatan!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.kegiatan = null;
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
