<template>
  <div class="entiti">
    <div class="col-12" :class="{ 'd-none': absenMasuk }">
      <div class="list-group">
        <div v-if="loadingProg">
          <div class="overlay">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div v-else>
          <div class="row">
            <!-- <dt>Jam Masuk</dt>
            <dd>
              <input
                type="time"
                class="form-control"
                :value="jamMasuk"
                @change="onChange($event.target.value)"
              />
            </dd> -->
            <dt>Nama Site</dt>
            <dd>
              <textarea
                class="form-control"
                name=""
                id=""
                cols="30"
                rows="10"
                placeholder="Isi nama site..."
                v-model="note"
              ></textarea>
            </dd>
            <dd>
              <button
                type="button"
                class="btn btn-primary float-end mx-auto"
                @click.prevent="saveLoc()"
                style="border-radius: 10px"
              >
                Absen Masuk
              </button>
            </dd>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-12 d-flex justify-content-center text-center"
      :class="{ 'd-none': !absenMasuk }"
    >
      <div class="row py-5">
        <img
          src="../../public/img/home/cek.gif"
          alt="cek"
          class="mx-auto d-block py-2"
          style="width: 100px"
        />
        <h5 class="text-success">{{ nama }}</h5>
        <h3>Anda Sudah Melakukan Absen Masuk</h3>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      dataEntitas: [],
      nama: localStorage.nama,
      geoloc: "0,0",
      absenMasuk: localStorage.masuk,
      timer: null,
      loadingProg: false,
      jamMasuk: "",
      note: "",
    };
  },
  created() {
    var time = new Date().toTimeString().split(" ")[0];
    var splitTime = time.split(":");
    var newTime = splitTime[0] + ":" + splitTime[1];
    this.jamMasuk = newTime;
    let dataEntt = JSON.parse(localStorage.getItem("entitas"));
    if (dataEntt === null && navigator.onLine) {
      axios
        .get(this.UrlApi + "getlocentitas", { headers: headers })
        .then((res) => {
          this.dataEntitas = res.data.data;
          localStorage.setItem("entitas", JSON.stringify(res.data.data));
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } else if (dataEntt !== null && (navigator.onLine || !navigator.onLine)) {
      this.dataEntitas = dataEntt;
    } else {
      this.dataEntitas = dataEntt;
    }
    this.timer = setInterval(() => {
      this.absenMasuk = localStorage.getItem("masuk") !== null ? true : false;
    }, 1000);
  },
  methods: {
    saveLoc() {
      this.loadingProg = true;
      let Dt = new Date();
      let Hrs = Dt.getHours();
      let Mnt = Dt.getMinutes();
      let Tm = (Hrs + ":" + Mnt).toString();
      const offset = Dt.getTimezoneOffset();
      Dt = new Date(Dt.getTime() - offset * 60 * 1000);
      let nowDates = Dt.toJSON().slice(0, 10).replace(/-/g, "/").toString();
      let urls = this.UrlApi;
      axios
        .post(
          urls + "absenmasuk",
          {
            id_entitas: 0,
            id_pegawai: parseInt(localStorage.getItem("id")),
            tanggal_absensi: nowDates,
            jam_masuk: Tm,
            keterangan: localStorage.address,
            note: this.note,
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            Swal.fire({
              icon: "success",
              title: "Berhasil!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.loadingProg = false;
            localStorage.setItem(
              "masuk",
              JSON.stringify([
                {
                  masuk: true,
                  absen: res.data.data[0]["absensi"],
                  keluar: false,
                  tanggal: res.data.data[0]["tanggal"],
                },
              ])
            );
          } else {
            Swal.fire({
              icon: "success",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
            this.loadingProg = false;
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    },
    onChange(event) {
      this.jamMasuk = event;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
