<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="lsitMasuk">
    <div class="row align-items-center sticky-top bg-white py-2">
      <div class="col-12 px-3">
        <input
          type="date"
          class="form-control"
          v-model="sel_date"
          @change="onChangeDate($event)"
        />
      </div>
      <!-- <div class="col-4">
        <button class="btn btn-success" @click.prevent="submitCari()">
          Pilih Tanggal
        </button>
      </div> -->
    </div>
    <div class="row py-2">
      <div class="col-10 px-3">
        <input
          type="text"
          class="form-control"
          placeholder="Cari berdasarkan nama entitas..."
          v-model="cariVal"
        />
      </div>
      <div class="col-2">
        <button type="submit" class="btn btn-primary" @click.prevent="cari">
          Cari
        </button>
      </div>
    </div>
    <div
      class="modal fade"
      id="timeabsenpulang"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="timeabsenpulangLabel"
      aria-hidden="true"
      :class="{ 'show d-block mt-0 px-0': showModals, '': !showModals }"
      v-bind:style="showModals ? 'background-color: #0000009c' : ''"
      v-bind:role="showModals ? 'dialog' : ''"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="timeabsenpulangLabel">
              Detail Absensi
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click.prevent="hideModalsBs()"
            ></button>
          </div>
          <div class="modal-body">
            <form ref="fEditable">
              <dl
                class="row g-2 py-3"
                v-for="(itm, idx) in dataView"
                :key="idx"
              >
                <dt>Kantor :</dt>
                <dd>{{ itm.note }}</dd>
                <dt>Tanggal :</dt>
                <dd>{{ formatDate(itm.tanggal_absensi) }}</dd>
                <dt>Jam Masuk :</dt>
                <dd>{{ itm.jam_masuk }}</dd>
                <dt>Jam Pulang :</dt>
                <dd>{{ itm.jam_pulang }}</dd>
                <dt>Lokasi Absen :</dt>
                <dd>{{ itm.keterangan }}</dd>
                <dt>Kegiatan :</dt>
                <dd>{{ itm.kegiatan }}</dd>
                <dt>Riwayat Ubah Lokasi :</dt>
                <dl
                  v-for="(val, idx) in dataRiwayat"
                  :key="idx"
                  class="row px-3"
                >
                  <div class="card m-2">
                    <div class="card-body text-muted">
                      <div class="card-title">
                        Pindah ke site {{ val.site }}
                      </div>
                      <div class="card-subtitle">Pada jam : {{ val.jam }}</div>
                      <p class="card-text">Keterangan : {{ val.keterangan }}</p>
                    </div>
                  </div>
                </dl>
              </dl>
            </form>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="clearfix"></div> -->
    <div
      class="container-fluid my-3"
      :style="{ height: windowHeight - 160 + 'px', overflow: 'auto' }"
      @scroll="handleScroll"
    >
      <div class="row g-0 d-flex justify-content-between">
        <!-- <div v-for="(row, index) in listOfEntitas" :key="index">
          <div
            class="card border-secondary m-2"
            data-bs-toggle="modal"
            data-bs-target="#timeabsenpulang"
            @click.prevent="showModalsBs(row.autono, row.id_pegawai)"
          >
            <div class="card-header bg-secondary text-white">
              {{ row.note }}
            </div>
          </div>
        </div> -->
        <div id="accordion" class="accordion-container">
          <div
            v-for="(item, index) in listOfEntitas"
            :key="index"
            :class="[
              'accordion card mb-2 border-dark',
              { 'is-open': isOpen.includes(index) },
            ]"
          >
            <div
              class="accordion-header card-header bg-secondary text-white"
              @click="toggleAccordion(index, item.autono)"
            >
              {{ item.nama_perusahaan }}
            </div>
            <div class="accordion-body p-0">
              <div v-for="(row, index) in listOfAbsensi" :key="index">
                <div
                  class="card"
                  data-bs-toggle="modal"
                  data-bs-target="#timeabsenpulang"
                  @click.prevent="showModalsBs(row.autono, row.id_pegawai)"
                >
                  <div
                    class="card-body bg-danger text-white"
                    v-if="row.jam_masuk === null"
                  >
                    <div class="row">
                      <div class="col-4">{{ row.nm_pegawai }}</div>
                      <div class="col-8">Belum Absen</div>
                    </div>
                  </div>
                  <div
                    class="card-body bg-warning"
                    v-else-if="row.jam_masuk === 'Sakit'"
                  >
                    <div class="row">
                      <div class="col-4">{{ row.nm_pegawai }}</div>
                      <div class="col-8">
                        {{ row.jam_masuk }} : {{ row.ket_sakit }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-body bg-success text-white"
                    v-else-if="row.jam_masuk === 'Izin'"
                  >
                    <div class="row">
                      <div class="col-4">{{ row.nm_pegawai }}</div>
                      <div class="col-8">
                        {{ row.jam_masuk }} : {{ row.ket_ijin }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="card-body bg-info text-white"
                    v-else-if="row.jam_masuk === 'Cuti'"
                  >
                    <div class="row">
                      <div class="col-4">{{ row.nm_pegawai }}</div>
                      <div class="col-8">
                        {{ row.jam_masuk }} : {{ row.ket_cuti }}
                      </div>
                    </div>
                  </div>
                  <div class="card-body" v-else>
                    <div class="row">
                      <div class="col-4">{{ row.nm_pegawai }}</div>
                      <div class="col-2">
                        {{
                          row.jam_masuk !== null
                            ? formatTime(row.jam_masuk)
                            : row.jam_masuk
                        }}
                      </div>
                      <div class="col-2">
                        {{
                          row.jam_pulang !== null
                            ? formatTime(row.jam_pulang)
                            : "-"
                        }}
                      </div>
                      <div class="col-4">{{ row.note }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { ref } from "vue";

const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
const month = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const idx = new Date().getMonth();
export default {
  data: function () {
    return {
      cariVal: ref(""),
      dataView: [],
      dataRiwayat: [],
      empty: true,
      windowHeight: window.innerHeight,
      sel_tahun: new Date().getFullYear(),
      sel_bulan: month[idx],
      sel_date: null,
      listOfAbsensi: [],
      listOfEntitas: [],
      isOpen: [],
      id: parseInt(localStorage.getItem("id")),
      page: 2,
      detilId: 0,
      openedAccordion: null,
      bulan: [
        {
          name: "Jan",
          value: "01",
        },
        {
          name: "Feb",
          value: "02",
        },
        {
          name: "Mar",
          value: "03",
        },
        {
          name: "Apr",
          value: "04",
        },
        {
          name: "Mei",
          value: "05",
        },
        {
          name: "Jun",
          value: "06",
        },
        {
          name: "Jul",
          value: "07",
        },
        {
          name: "Ags",
          value: "08",
        },
        {
          name: "Sep",
          value: "09",
        },
        {
          name: "Okt",
          value: "10",
        },
        {
          name: "Nov",
          value: "11",
        },
        {
          name: "Des",
          value: "12",
        },
      ],
      btnDisabled: true,
      btnLoadMoreName: "Muat Lebih Banyak",
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2020 },
        (value, index) => 2021 + index
      );
    },
  },
  created() {
    this.sel_date = new Date().toISOString().slice(0, 10);
    this.loadEntitas();
    // try {
    //   axios
    //     .post(
    //       this.UrlApi + "listabsensi/pic?page=1",
    //       {
    //         tanggal_absensi: this.sel_date,
    //         id: localStorage.getItem("id"),
    //         jabatan: localStorage.getItem("jabatan"),
    //       },
    //       { headers: headers }
    //     )
    //     .then((res) => {
    //       if (res.data.success && res.data.success !== null) {
    //         this.listOfAbsensi = res.data.data;
    //         console.log(this.listOfAbsensi);
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Gagal!",
    //           text: res.data.message,
    //           showConfirmButton: false,
    //           timer: 1500,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       if (err.response) {
    //         console.log("error response");
    //       } else if (err.request) {
    //         console.log("error request");
    //       } else {
    //         console.log("error");
    //       }
    //     });
    // } catch (error) {
    //   console.log(error);
    // }
  },
  mounted() {
    window.addEventListener("scroll", this.onResize);
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatTime(time) {
      let splitedTime = time.split(":");
      let newTime = splitedTime[0] + ":" + splitedTime[1];
      return newTime;
    },
    cari() {
      this.isOpen = this.isOpen.filter((i) => i !== this.openedAccordion);
      try {
        axios
          .post(
            this.UrlApi + "getpicentitas",
            {
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
              keyword: this.cariVal,
              nama: localStorage.getItem("nama"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.listOfEntitas = res.data.data;
              // if (this.cariVal !== "") {
              //   this.btnDisabled = true;
              // } else {
              // }
              this.btnDisabled = true;
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    onButtonClik(event) {
      window.location.href =
        "/detil-absensi?q=" + event.target.getAttribute("data-absensi");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleScroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      // console.log(e.target.scrollTop.toFixed(0));
      if (scrollTop + clientHeight >= scrollHeight) {
        // console.log("Yay!");
        // this.bottom = true;
        //     Swal.fire({
        //       position: "bottom",
        //       text: "Semua Data Berhasi Dikeluarkan",
        //       showClass: {
        //         popup: `
        //     animate__animated
        //     animate__fadeInUp
        //     animate__faster
        //   `,
        //       },
        //       hideClass: {
        //         popup: `
        //     animate__animated
        //     animate__fadeOutDown
        //     animate__faster
        //   `,
        //       },
        //       grow: "row",
        //       showConfirmButton: false,
        //       timer: 1500,
        //     });
      } else {
        // this.bottom = false;
      }
    },
    onChangeDate(event) {
      this.btnDisabled = true;
      this.loadEntitas();
      this.cariVal = null;
      // this.btnLoadMoreName = "Muat Lebih Banyak";
      this.sel_date = event.target.value;
      try {
        axios
          .post(
            this.UrlApi + "listabsensi/pic",
            {
              tanggal_absensi: this.sel_date,
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.listOfAbsensi = res.data.data;
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    async submitCari() {
      try {
        axios
          .post(
            this.UrlApi + "listabsensi/pic",
            {
              tanggal_absensi: this.sel_date,
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.listOfAbsensi = res.data.data.absensi;
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    showModalsBs(autono, id_peg) {
      if (id_peg === null) {
        return;
      } else {
        this.showModals = true;
        try {
          axios
            .post(
              this.UrlApi + "listabsensi/detil",
              {
                autono: autono,
                id_peg: id_peg,
              },
              { headers: headers }
            )
            .then((res) => {
              if (res.data.success && res.data.success !== null) {
                this.empty = res.data.data.length > 0 ? false : true;
                this.dataView = res.data.data;
                // console.log(res.data.data);
              } else {
                this.empty = true;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response");
              } else if (err.request) {
                console.log("error request");
              } else {
                console.log("error");
              }
              this.empty = true;
            });
        } catch (error) {
          console.log(error);
        }

        try {
          axios
            .post(
              this.UrlApi + "listabsensi/riwayat",
              {
                autono: autono,
                id_peg: id_peg,
              },
              { headers: headers }
            )
            .then((res) => {
              if (res.data.success && res.data.success !== null) {
                this.empty = res.data.data.length > 0 ? false : true;
                // this.dataView = res.data.data;
                this.dataRiwayat = res.data.data;
              } else {
                this.empty = true;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response");
              } else if (err.request) {
                console.log("error request");
              } else {
                console.log("error");
              }
              this.empty = true;
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
    hideModalsBs() {
      this.showModals = false;
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listabsensi/group?page=" + pages,
            {
              tanggal_absensi: this.sel_date,
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.empty = res.data.data.length > 0 ? true : false;
              // console.log(res.data.data.data.length);
              if (res.data.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(
                  this.listOfEntitas,
                  res.data.data.data
                );
                // console.log(this.listOfAbsensi);
              } else {
                this.btnDisabled = true;
                // console.log("mentok", this.btnDisabled);
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    loadEntitas() {
      try {
        axios
          .post(
            this.UrlApi + "getpicentitas",
            {
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
              nama: localStorage.getItem("nama"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.empty = res.data.data.length > 0 ? false : true;
              this.listOfEntitas = res.data.data;
              // console.log(res.data.data);
              this.btnDisabled = true;
            } else {
              this.empty = true;
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
            this.empty = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    toggleAccordion(index, id_entitas) {
      // console.log(id_entitas);
      if (this.openedAccordion === index) {
        this.isOpen = this.isOpen.filter((i) => i !== index);
        this.openedAccordion = null;
        return;
      } else {
        if (this.isOpen.includes(index)) {
          this.isOpen = this.isOpen.filter((i) => i !== index);
          return;
        }
      }
      this.isOpen = this.isOpen.filter((i) => i !== this.openedAccordion);
      this.openedAccordion = index;

      this.isOpen.push(index);
      this.listOfAbsensi = [];
      try {
        axios
          .post(
            this.UrlApi + "listabsensi/pic",
            {
              tanggal_absensi: this.sel_date,
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
              id_entitas: id_entitas,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.listOfAbsensi = res.data.data;
              // console.log(res.data.data);
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  // watch: {
  //   cariVal(v) {
  //     if (v) {
  //       this.cari(v);
  //     }
  //   },
  // },
};
</script>
<style>
.accordion:not(.is-open) .accordion-body {
  display: none;
}
.accordion-body {
  font-size: 10px;
  font-weight: bold;
}
</style>
