<template>
  <div class="lstIzin">
    <div class="col-12">
      <div class="list-group">
        <div v-for="(item, index) in listOfizin" :key="index" class="py-1 px-2">
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ item.site }}</h5>
              <small class="text-muted">{{ item.tanggal_absensi }}</small>
            </div>
            <p class="mb-0">Jam : {{ item.jam }}</p>
            <p class="mb-1">Keterangan : {{ item.keterangan }}</p>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfizin: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      tglsaatini: new Date().toISOString().slice(0, 10),
      headers: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listubahlokasi?page=" + pages,
            {
              id: localStorage.getItem("id"),
            },
            {
              headers: this.headers,
            }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.data[0].data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(
                  this.listOfizin,
                  res.data.data[0].data
                );
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    try {
      axios
        .post(
          this.UrlApi + "listubahlokasi?page=",
          {
            id: localStorage.getItem("id"),
          },
          {
            headers: this.headers,
          }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.dataEmpty = res.data.data.length > 0 ? true : false;
            this.listOfizin = res.data.data[0].data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
