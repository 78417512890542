<template>
  <div class="dtlAbsen">
    <div class="col-12 py-3">
      <div class="list-group">
        <div v-for="item in listOfizin" :key="item.autono" class="py-1 px-2">
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ item.nama_aktifitas }}</h5>
              <button
                class="btn btn-warning btn-sm float-end"
                type="button"
                @click.prevent="detilProgress($event, item.autono)"
                :data-progress="item.parent_id"
                :data-ptp="item.id_penetapan_proyek"
              >
                Detil
              </button>
            </div>
            <p class="mb-0">Proyek : {{ item.nama_kontrak }}</p>
            <p class="mb-0">Dari : {{ item.tanggal_mulai }}</p>
            <p class="mb-0">Sampai : {{ item.tanggal_selesai }}</p>
            <p class="mb-0">Ket : {{ item.ket }}</p>
            <small
              class="text-danger"
              v-if="item.status_update == 'Belum Update'"
              >Belum Update</small
            >
            <small
              class="text-success"
              v-else-if="item.status_update == 'Sudah Update'"
              >Sudah Update</small
            >
          </a>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
        <div
          class="modal fade"
          id="timeabsenpulang"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="timeabsenpulangLabel"
          aria-hidden="true"
          :class="{ 'show d-block mt-0 px-0': showModals, '': !showModals }"
          v-bind:style="showModals ? 'background-color: #0000009c' : ''"
          v-bind:role="showModals ? 'dialog' : ''"
        >
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="timeabsenpulangLabel">
                  Tambah Data
                </h5>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  @click.prevent="hideModalsBs()"
                ></button>
              </div>
              <div class="modal-body">
                <form @submit.prevent="updateData(itm.autono)" ref="fEditable">
                  <dl
                    class="row g-2 py-3"
                    v-for="(itm, idx) in listOfizin"
                    :key="idx"
                  >
                    <dt>Tanggal :</dt>
                    <dd>
                      <input
                        type="date"
                        class="form-control"
                        :value="itm.tanggal_upload"
                        required
                      />
                    </dd>
                    <dt>Judul Kegiatan :</dt>
                    <dd>
                      <input
                        type="text"
                        class="form-control"
                        :value="itm.judul"
                        required
                      />
                    </dd>
                    <dt>Upload File :</dt>
                    <dd>
                      <input
                        type="file"
                        ref="file"
                        class="form-control"
                        v-on:change="onChnageFileUpload()"
                      />
                    </dd>
                    <dt>Progres Seluruh Kegiatan (%) :</dt>
                    <dd>
                      <input
                        type="number"
                        class="form-control"
                        :value="itm.progres"
                        max="100"
                      />
                    </dd>
                    <dt>Biaya :</dt>
                    <dd>
                      <input
                        type="text"
                        class="form-control"
                        :value="itm.biaya"
                      />
                    </dd>
                    <dt>Keterangan :</dt>
                    <dd>
                      <textarea
                        class="form-control"
                        :value="itm.keterangan"
                      ></textarea>
                    </dd>
                  </dl>
                  <button type="submit" class="btn btn-primary mt-1 btn-block">
                    Simpan
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      page: 2,
      id: parseInt(localStorage.getItem("id")),
      listOfizin: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      file: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    detilProgress(event, autono) {
      window.location.href =
        "/detil-progress-report?q=" +
        btoa(event.target.getAttribute("data-progress")) +
        "&r=" +
        btoa(event.target.getAttribute("data-ptp")) +
        "&s=" +
        btoa(autono);
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listaktproyek?page=" + pages,
            {
              id: this.id,
            },
            { headers: headers }
          )
          .then((res) => {
            // console.log(res.data.success);
            if (res.data.result !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.result.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(
                  this.listOfizin,
                  res.data.result.data
                );
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: "",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    showModalsBs() {
      this.showModals = true;
    },
    hideModalsBs() {
      this.showModals = false;
    },
    onChnageFileUpload() {
      this.file = this.$refs.file[0].files[0];
    },
  },
  created() {
    try {
      axios
        .post(
          this.UrlApi + "listaktproyek?page=",
          {
            id: this.id,
          },
          { headers: headers }
        )
        .then((res) => {
          // console.log(res.data.result.data);
          if (res.data.result.data !== null) {
            this.dataEmpty = res.data.result.data.length > 0 ? true : false;
            this.listOfizin = res.data.result.data;
            // console.log(res.data.data.data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: "",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
