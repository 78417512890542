<template>
  <div class="sakit">
    <BackSection :menu="name" />
    <div class="container-fluid">
      <FormSakit />
    </div>
  </div>
</template>

<script>
import FormSakit from "@/components/FormSakit.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Penugasan Tim Teknis",
    };
  },
  components: {
    FormSakit,
    BackSection,
  },
};
</script>
