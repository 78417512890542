<template>
  <div class="izin">
    <BackSection :menu="name" />
    <div class="container-fluid">
      <FormIzin />
    </div>
  </div>
</template>

<script>
import FormIzin from "@/components/FormIzin.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Form Izin",
    };
  },
  components: {
    FormIzin,
    BackSection,
  },
};
</script>
