<template>
  <back-section :menu="nama" />
  <list-data-progress-report-view />
</template>
<script>
import BackSection from "@/components/BackSection.vue";
import ListDataProgressReportView from "@/components/ListDataProgressReportView.vue";
export default {
  setup() {
    return {
      nama: "List Progress Report",
    };
  },
  components: { BackSection, ListDataProgressReportView },
};
</script>
