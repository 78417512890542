<template>
  <back-section :menu="nama" />
  <list-cuti-approve />
</template>
<script>
import ListCutiApprove from "@/components/Approval/ListDataCutiApprove.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Approval Cuti",
    };
  },
  components: { BackSection, ListCutiApprove },
};
</script>
