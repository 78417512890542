<template>
  <div class="lstIzin">
    <div class="col-12">
      <div class="list-group">
        <div v-for="item in listOfizin" :key="item.autono" class="py-1 px-2">
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Izin Sakit</h5>
              <!-- <small class="text-muted">{{
                formatDate(item.created_on)
              }}</small> -->
            </div>
            <p class="mb-0">Nama : {{ item.nm_pegawai }}</p>
            <p class="mb-0">Dari : {{ formatDate(item.tanggal_awal) }}</p>
            <p class="mb-0">Sampai : {{ formatDate(item.tanggal_akhir) }}</p>
            <p class="mb-1">Ket : {{ item.keterangan }}</p>
            <div class="col-3" v-if="item.file_upload == 0">-</div>
            <div class="col-3 mx-3" v-else>
              <button class="btn" @click="openImg(img + item.file_upload)">
                <img
                  src="../../public/img/home/folder.png"
                  class="img-responsive d-block w60"
                />
              </button>
            </div>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfizin: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      img: "https://api.vitech.asia/storage/upload/sakit/",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listsakit/pic?page=" + pages,
            {
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
            },
            {
              headers: headers,
            }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(this.listOfizin, res.data.data);
                // console.log(this.listOfizin);
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    openImg(url) {
      // console.log(url);
      window.open(url);
    },
  },
  created() {
    try {
      axios
        .post(
          this.UrlApi + "listsakit/pic?page=1",
          {
            id: localStorage.getItem("id"),
            jabatan: localStorage.getItem("jabatan"),
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.dataEmpty = res.data.data.length > 0 ? true : false;
            this.listOfizin = res.data.data;
            // console.log(res.data.data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
