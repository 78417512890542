<template>
  <div class="izin">
    <BackSection :menu="name" :sticky="false" />
    <div
      class="container-fluid"
      :style="{
        width: '100%',
        overflow: 'auto',
        padding: '15px',
        height: window.height - 60 + 'px',
      }"
    >
      <surat-cuti-disetujui
        :hakCuti="12"
        :nama="propNama"
        :sisaCuti="propSisaCuti"
        :tanggalBerakhir="propTanggalBerakhir"
        :tanggalMulai="propTanggalMulai"
        :tanggalSaatIni="propNow"
        :kembaliTanggal="propTanggalKembali"
        :keterangan="propKeterangan"
        :kontak="propKontak"
      />
    </div>
  </div>
</template>
<script>
import SuratCutiDisetujui from "@/components/SuratCutiDisetujui.vue";
import BackSection from "@/components/BackSection.vue";
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      name: "Preview Form Cuti",
      window: {
        width: 0,
        height: 0,
      },
      propNama: this.$route.query.nama,
      propTanggalMulai: this.$route.query.awal,
      propTanggalBerakhir: this.$route.query.akhir,
      propTanggalKembali: this.$route.query.masuk,
      propKeterangan: this.$route.query.ket,
      propKontak: this.$route.query.kontak,
      propNow: this.$route.query.now,
      propSisaCuti: 0,
      propCutiTahunKemarin: 12,
    };
  },
  components: { SuratCutiDisetujui, BackSection },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    try {
      axios
        .post(
          this.UrlApi + "dataizincuti",
          {
            id: localStorage.getItem("id"),
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.propSisaCuti = res.data.data.sisa;
          } else {
            this.empty = true;
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
          this.empty = true;
        });
    } catch (error) {
      console.log(error);
    }
  },
  unmounted() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
  },
};
</script>
