<template>
  <div class="lsAbs">
    <BackSection :menu="name" />
    <div class="container-fluid py-2">
      <DataPicMonitoring />
    </div>
  </div>
</template>
<script>
import DataPicMonitoring from "@/components/DataPicMonitoring.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "PIC Monitoring",
    };
  },
  components: {
    BackSection,
    DataPicMonitoring,
  },
};
</script>
