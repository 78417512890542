<template>
  <div class="form-sakit">
    <div class="overlay" v-show="loadingProg">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <form @submit.prevent="submitsakit" class="row g-3" ref="fSakit">
      <div class="col-6">
        <label>Tanggal Ambil Izin</label>
        <input type="date" class="form-control" v-model="awal" required />
      </div>
      <div class="col-6">
        <label>Tanggal Selesai Izin</label>
        <input type="date" class="form-control" v-model="akhir" required />
      </div>
      <button class="btn btn-primary mt-4" type="button" @click="upload">
        Upload
      </button>
      <div class="col-12">
        <image-compressor
          class="compressor d-none"
          :done="getFiles"
          :scale="scale"
          :quality="quality"
          ref="compressor"
        ></image-compressor>
        <span>{{ filename }}</span>
        <!-- <input
          type="file"
          ref="file"
          class="form-control"
          v-on:change="onChnageFileUpload()"
        /> -->
      </div>
      <small>format file : <code>jpg, png, jpeg</code></small>
      <div class="col-12">
        <label>Keterangan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="keterangan"
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Ajukan</button>
        <a @click="$router.go(-1)" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
import imageCompressor from "./Compressor/vue-image-compressor.vue";
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  components: {
    imageCompressor,
  },
  data: function () {
    return {
      awal: null,
      akhir: null,
      keterangan: null,
      file: "",
      id: parseInt(localStorage.getItem("id")),
      loadingProg: false,
      base64: null,
      filename: null,
      mimetype: null,
      compressed: {},
      scale: 50,
      quality: 0,
    };
  },
  methods: {
    async submitsakit() {
      this.loadingProg = true;
      // this.socket.emit("sakit", {
      //   nama: localStorage.getItem("nama"),
      //   keterangan: this.keterangan,
      //   tanggal_awal: this.formatDate(this.awal),
      //   tanggal_akhir: this.formatDate(this.akhir),
      //   img: this.compressed,
      //   filename: this.filename,
      //   mimetype: this.mimetype,
      //   nomor: JSON.parse(localStorage.getItem("nomorpic")),
      // });
      try {
        axios
          .post(
            this.UrlApi + "pengajuan/sakit",
            {
              tanggal_awal: this.awal,
              tanggal_akhir: this.akhir,
              keterangan: this.keterangan,
              id_employee: this.id,
              file: this.compressed,
              location_name: localStorage.getItem("jabatan"),
              level_name: localStorage.getItem("jabatan"),
              filename: this.filename,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.getFcmToken();
              this.loadingProg = false;

              const pesan =
                "*Pengajuan Sakit* :" +
                "\n" +
                "Nama : " +
                "\n" +
                localStorage.getItem("nama") +
                "\n" +
                "Tanggal Awal : " +
                "\n" +
                this.formatDate(this.awal) +
                "\n" +
                "Tanggal Akhir : " +
                "\n" +
                this.formatDate(this.akhir) +
                "\n" +
                "Keterangan : " +
                "\n" +
                this.keterangan +
                "\n" +
                "\n" +
                "_*Silahkan cek di aplikasi presensi untuk approve atau decline*_";
              let nomor = JSON.parse(localStorage.getItem("nomorpic"));
              for (var i = 0; i < nomor.length; i++) {
                let formatted = nomor[i].no_tlp.replace(/\D/g, "");

                if (formatted.startsWith("0")) {
                  formatted = "62" + formatted.substr(1);
                }

                //jika ada file
                if (res.data.data !== "0") {
                  //kirim ke pic
                  try {
                    axios
                      .post(this.UrlApi + "postwa", {
                        url: "chat/sendimage/" + formatted,
                        caption: pesan,
                        image:
                          "https://api.vitech.asia/storage/upload/sakit/" +
                          res.data.data,
                      })
                      .then(() => {
                        // console.log(res);
                      });
                  } catch (error) {
                    console.log(error);
                  }
                } else {
                  //jika tidak ada file

                  //kirim ke pic
                  try {
                    axios
                      .post(this.UrlApi + "postwa", {
                        url: "chat/sendmessage/" + formatted,
                        message: pesan,
                      })
                      .then(() => {
                        // console.log(res);
                      });
                  } catch (error) {
                    console.log(error);
                  }
                }
              }

              //kirim ke group
              if (res.data.data != "0") {
                try {
                  axios
                    .post(this.UrlApi + "postwa", {
                      url: "group/sendimage/HRD info",
                      caption: pesan,
                      image:
                        "https://api.vitech.asia/storage/upload/sakit/" +
                        res.data.data,
                    })
                    .then(() => {
                      // console.log(res);
                    });
                } catch (error) {
                  console.log(error);
                }
              } else {
                //kirim ke group
                try {
                  axios
                    .post(this.UrlApi + "postwa", {
                      url: "group/sendmessage/HRD info",
                      message: pesan,
                    })
                    .then(() => {
                      // console.log(res);
                    });
                } catch (error) {
                  console.log(error);
                }
              }
              Swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              this.$nextTick(function () {
                this.$refs.fSakit.reset();
              });
              this.filename = null;
            } else {
              this.loadingProg = false;
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
              this.loadingProg = false;
            } else if (err.request) {
              console.log("error request");
              this.loadingProg = false;
            } else {
              console.log("error");
              this.loadingProg = false;
            }
          });
      } catch (error) {
        console.log(error);
        this.loadingProg = false;
      }
    },
    upload() {
      let compressor = this.$refs.compressor.$el;
      compressor.click();
    },
    getFiles(obj) {
      this.compressed = obj.compressed.base64;
      this.mimetype = obj.compressed.type;
      this.filename = obj.compressed.name;
      this.ukuran = obj.compressed.file.size;
      // console.log(this.compressed);
      this.quality = 50;
      // console.log(parseInt(b));
    },
    // createBase64Image(FileObject) {
    //   const reader = new FileReader();
    //   reader.onload = (event) => {
    //     this.base64 = event.target.result;
    //   };
    //   reader.readAsDataURL(FileObject);
    // },
    // onChnageFileUpload() {
    //   this.file = this.$refs.file.files[0];
    //   this.filename = this.$refs.file.files[0].name;
    //   this.mimetype = this.$refs.file.files[0].type;
    //   this.createBase64Image(this.file);
    // },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
