<template>
  <back-section :menu="nama" />
  <list-data-sakit />
</template>
<script>
import ListDataSakit from "@/components/ListDataSakit.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Izin Sakit",
    };
  },
  components: { ListDataSakit, BackSection },
};
</script>
