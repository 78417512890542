<template>
  <div class="cuti">
    <BackSection :menu="name" />
    <div class="container-fluid">
      <ChangeHoliday />
    </div>
  </div>
</template>

<script>
import ChangeHoliday from "@/components/FormChangeHoliday.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Form Pengajuan PH",
    };
  },
  components: {
    ChangeHoliday,
    BackSection,
  },
};
</script>
