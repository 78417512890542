<template>
  <div>
    <form @submit.prevent="submitcuti" class="row g-3" ref="fPh">
      <div class="col-12">
        <label>Hari Libur Nasional</label>
        <select
          class="form-control"
          v-model="selectedIdLibur"
          @change="changeDate()"
          required
        >
          <option value="00" disabled>Pilih Hari Libur</option>
          <option
            v-for="list in this.listLibur"
            :key="list"
            :value="list.autono"
            :tanggal="list.tanggal"
          >
            {{ list.nama_hari_libur }}
          </option>
        </select>
      </div>
      <div class="col-12">
        <label>Tanggal</label>
        <input type="date" class="form-control" v-model="tanggalPH" required />
      </div>
      <div class="col-12">
        <label>Keterangan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="keterangan"
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Ajukan</button>
        <a @click="$router.go(-1)" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// const Swal = require("sweetalert2");
export default {
  data: function () {
    return {
      idpeg: null,
      listLibur: [],
      selectedIdLibur: "00",
      keterangan: null,
      tanggalPH: null,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("key"),
      },
      tanggalLibur: null,
      namaLibur: null,
    };
  },
  mounted() {
    this.idpeg = localStorage.getItem("id");
  },
  created() {
    try {
      axios
        .get(this.UrlApi + "liburnasional", { headers: this.headers })
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.listLibur = res.data.data[0];
          } else {
            // console.log(res);
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    changeDate() {
      var options = event.target.options;
      if (options.selectedIndex > -1) {
        var tanggal = options[options.selectedIndex].getAttribute("tanggal");
        this.tanggalLibur = tanggal;
        this.namaLibur = options[options.selectedIndex].text;
      }
    },
    submitcuti() {
      try {
        axios
          .post(
            this.UrlApi + "storeholiday",
            {
              id_pegawai: this.idpeg,
              id_libur_nasional: this.selectedIdLibur,
              tanggal_ph: this.tanggalPH,
              ket: this.keterangan,
              approve: 0,
              tanggal_libur: this.tanggalLibur,
            },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.message != "gagal") {
              if (res.data.message != "exists") {
                Swal.fire({
                  icon: "success",
                  title: "Berhasil!",
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });

                const pesan =
                  "*Pengajuan PH* :" +
                  "\n" +
                  "*Nama* : " +
                  "\n" +
                  localStorage.getItem("nama") +
                  "\n" +
                  "*Hari Libur yang Diambil* : " +
                  "\n" +
                  this.namaLibur +
                  "\n" +
                  "*Tanggal* : " +
                  "\n" +
                  this.tanggalPH +
                  "\n" +
                  "*Keterangan* : " +
                  "\n" +
                  this.keterangan +
                  "\n" +
                  "\n" +
                  "_*Silahkan cek di aplikasi presensi untuk approve atau decline*_";

                //kirim ke group
                try {
                  axios
                    .post(this.UrlApi + "postwa", {
                      url: "group/sendmessage/HRD info",
                      message: pesan,
                    })
                    .then(() => {
                      // console.log(res);
                      this.selectedIdLibur = "00";
                      this.keterangan = null;
                      this.tanggalPH = null;
                    });
                } catch (error) {
                  console.log(error);
                }
              } else {
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: "Anda sudah mengambil PH pada tanggal tersebut",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.selectedIdLibur = "00";
                this.keterangan = null;
                this.tanggalPH = null;
              }
            } else {
              Swal.fire({
                icon: "info",
                title: "",
                text: "Maaf, anda tidak bisa mengajukan dikarenakan anda tidak terjadwal masuk  di hari libur nasional tersebut",
                showConfirmButton: true,
                timer: 3500,
              });
              this.selectedIdLibur = "00";
              this.keterangan = null;
              this.tanggalPH = null;
            }
          });
      } catch (error) {
        console.log(error);
        this.loadingProg = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
