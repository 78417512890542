<template>
  <div class="menu">
    <ul class="text-white pt-5 list-unstyled mx-3">
      <a href="#" class="text-decoration-none text-white">
        <li>Profile</li>
      </a>
      <a href="/list-absen" class="text-decoration-none text-white">
        <li>List Absensi</li>
      </a>
      <a
        href="/list-ph"
        class="text-decoration-none text-white"
        v-show="entitas == 7"
      >
        <li>List Pengajuan PH</li>
      </a>
      <a
        href="/list-eo"
        class="text-decoration-none text-white"
        v-show="entitas == 7"
      >
        <li>List Pengajuan Overtime</li>
      </a>
      <a
        href="/list-libur-eo"
        class="text-decoration-none text-white"
        v-show="entitas == 7"
      >
        <li>List Pengajuan Extra Off</li>
      </a>
      <a href="/list-sakit" class="text-decoration-none text-white">
        <li>List Izin Sakit</li>
      </a>
      <li>
        List Izin
        <ul class="list-unstyled text-white ps-4">
          <a href="/list-izin" class="text-decoration-none text-white">
            <li>Semua</li>
          </a>
          <a href="/list-izin-pending" class="text-decoration-none text-white">
            <li>Pending</li>
          </a>
          <a href="/list-izin-setuju" class="text-decoration-none text-white">
            <li>Disetujui</li>
          </a>
          <a
            href="/list-izin-tidak-setuju"
            class="text-decoration-none text-white"
          >
            <li>Ditolak</li>
          </a>
        </ul>
      </li>
      <li>
        List Izin Cuti
        <ul class="list-unstyled text-white ps-4">
          <a href="#" class="text-decoration-none text-white" @click="showCuti">
            <li>Sisa Cuti Tahun Kemarin</li>
          </a>
          <a href="/list-cuti" class="text-decoration-none text-white">
            <li>Semua</li>
          </a>
          <a href="/list-cuti-pending" class="text-decoration-none text-white">
            <li>Pending</li>
          </a>
          <a href="/list-cuti-setuju" class="text-decoration-none text-white">
            <li>Disetujui</li>
          </a>
          <a
            href="/list-cuti-tidak-setuju"
            class="text-decoration-none text-white"
          >
            <li>Ditolak</li>
          </a>
        </ul>
      </li>
      <a href="/list-ubah-lokasi" class="text-decoration-none text-white">
        <li>List Ubah Lokasi</li>
      </a>
      <hr />
      <h6
        v-show="
          jabatan == 'HR MANAGEMENT' ||
          jabatan == 'OWNER' ||
          jabatan == 'DIREKSI' ||
          jabatan == 'MEDIA'
        "
        class="text-muted"
      >
        Data Pegawai
      </h6>
      <a
        href="/data-pic-monitoring"
        class="text-decoration-none text-white"
        v-show="
          jabatan == 'HR MANAGEMENT' ||
          jabatan == 'OWNER' ||
          jabatan == 'DIREKSI' ||
          jabatan == 'MEDIA'
        "
      >
        <li>PIC Monitoring</li>
      </a>
      <a
        href="/data-absensi-pegawai"
        class="text-decoration-none text-white"
        v-show="
          jabatan == 'HR MANAGEMENT' ||
          jabatan == 'OWNER' ||
          jabatan == 'DIREKSI' ||
          jabatan == 'MEDIA'
        "
      >
        <li>List Absensi Pegawai</li>
      </a>
      <a
        v-show="
          jabatan == 'HR MANAGEMENT' ||
          jabatan == 'OWNER' ||
          jabatan == 'DIREKSI' ||
          jabatan == 'MEDIA'
        "
        href="/list-cuti-all"
        class="text-decoration-none text-white"
      >
        <li>List Cuti Pegawai</li>
      </a>
      <a
        v-show="
          jabatan == 'HR MANAGEMENT' ||
          jabatan == 'OWNER' ||
          jabatan == 'DIREKSI' ||
          jabatan == 'MEDIA'
        "
        href="/list-sakit-all"
        class="text-decoration-none text-white"
      >
        <li>List Sakit Pegawai</li>
      </a>
      <a
        v-show="
          jabatan == 'HR MANAGEMENT' ||
          jabatan == 'OWNER' ||
          jabatan == 'DIREKSI' ||
          jabatan == 'MEDIA'
        "
        href="/list-izin-all"
        class="text-decoration-none text-white"
      >
        <li>List Izin Pegawai</li>
      </a>
      <a
        href="/list-ph-approve"
        class="text-decoration-none text-white"
        v-show="jabatan == 'HR MANAGEMENT'"
      >
        <li>List Pegawai PH</li>
      </a>
      <a
        href="/list-eo-approve"
        class="text-decoration-none text-white"
        v-show="jabatan == 'HR MANAGEMENT'"
      >
        <li>List Pegawai Overtime</li>
      </a>
      <a
        href="/list-libur-eo-approve"
        class="text-decoration-none text-white"
        v-show="jabatan == 'HR MANAGEMENT'"
      >
        <li>List Pegawai Extra Off</li>
      </a>
      <a href="/faq" class="text-decoration-none text-white">
        <li>Tentang Aplikasi</li>
      </a>
      <button
        type="button"
        class="btn btn-link w-100 text-decoration-none text-white text-start p-0 m-0"
        @click.prevent="logout()"
      >
        <li>Keluar</li>
      </button>
    </ul>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");

export default {
  data() {
    return {
      headers: "",
      jabatan: localStorage.getItem("jabatan"),
      entitas: localStorage.getItem("pt"),
    };
  },
  methods: {
    showCuti() {
      const tanggalSekarang = new Date();
      let tahun = tanggalSekarang.getFullYear();

      let ijinLocal = localStorage.getItem("ijin");
      let parsedIjinLocal = JSON.parse(ijinLocal);

      Swal.fire({
        icon: "info",
        // title: "Sisa Cuti Tahun Sebelumnya",
        text: `Sisa cuti anda di tahun ${tahun - 1} : ${
          parsedIjinLocal[0].cuti_tahun_kemarin
        }`,
      });
    },
    logout() {
      Swal.fire({
        title: "Keluar",
        text: "Apa anda yakin akan keluar dari aplikasi VTA Presensi?",
        icon: "warning",
        confirmButtonColor: "#DD6B55",
        denyButtonColor: "#333333",
        confirmButtonText: "Ya, keluar",
        denyButtonText: "Tidak, Tetap di aplikasi",
        showDenyButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          // console.log(result.isConfirmed);
          try {
            axios
              .post(this.UrlApi + "logout", {}, { headers: this.headers })
              .then(() => {
                sessionStorage.removeItem("key");
                window.location.href = "/login";
              })
              .catch((err) => {
                if (err.response) {
                  console.log("error response");
                } else if (err.request) {
                  console.log("error request");
                } else {
                  console.log("error");
                }
              });
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("cancle");
        }
      });
    },
  },
  created() {
    this.headers = {
      "Content-Type": "multipart/form-data",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };
  },
};
</script>
