<template>
  <div class="lstIzin">
    <div class="col-12">
      <div class="list-group">
        <div class="p-2">
          <span class="badge rounded-pill bg-warning text-uppercase text-dark"
            >Total Jam Overtime : {{ convertToHMS(total) }}</span
          >
        </div>
        <div v-for="(item, index) in listOfeo" :key="index" class="py-1 px-2">
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Pengajuan Overtime</h5>
              <button
                class="btn btn-warning btn-sm"
                data-bs-toggle="modal"
                data-bs-target="#timeabsenpulang"
                @click.prevent="showModalsBs(item.autono)"
              >
                Ubah Data
              </button>
            </div>
            <p class="mb-1">Tanggal : {{ item.tanggal }}</p>
            <p class="mb-1">Jam Mulai : {{ item.jam_mulai }}</p>
            <p class="mb-1">Jam Selesai : {{ item.jam_selesai ?? "-" }}</p>
            <p class="mb-1">Keterangan : {{ item.ket }}</p>
            <!-- <hr /> -->
            <p class="mb-1" v-show="item.jam_selesai != null">
              Total EO:
              {{
                hitungSelisih(
                  item.jam_pulang ?? item.jam_mulai,
                  item.jam_selesai
                )
              }}
            </p>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-block': btnDisabled }"
          :disabled="btnDisabled"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
    <div
      class="modal fade"
      id="timeabsenpulang"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="timeabsenpulangLabel"
      aria-hidden="true"
      :class="{ 'show d-block mt-0 px-0': showModals, '': !showModals }"
      v-bind:style="showModals ? 'background-color: #0000009c' : ''"
      v-bind:role="showModals ? 'dialog' : ''"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="timeabsenpulangLabel">Ubah Data</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click.prevent="hideModalsBs()"
            ></button>
          </div>
          <div class="modal-body">
            <form
              @submit.prevent="updateData(editValue.autono)"
              ref="fEditable"
            >
              <dl class="row g-2">
                <dt>Tanggal :</dt>
                <dd>{{ formatDate(editValue.tanggal) }}</dd>
                <dt>Jam Masuk :</dt>
                <dd>{{ editValue.jam_mulai }}</dd>
                <dt>Jam Selesai :</dt>
                <dd>
                  <input
                    type="time"
                    class="form-control"
                    v-model="editValue.jam_selesai"
                    ref="jamPulangRef"
                    required
                  />
                </dd>
                <dt>Keterangan :</dt>
                <dd>{{ editValue.ket }}</dd>
              </dl>
              <button type="submit" class="btn btn-primary mt-1 btn-block">
                Simpan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfeo: [],
      dataEmpty: true,
      jamSelesai: null,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      tglsaatini: new Date().toISOString().slice(0, 10),
      headers: "",
      dataView: [],
      editValue: {
        autono: "",
        tanggal: "",
        jam_mulai: "",
        jam_selesai: "",
        ket: "",
      },
      total: localStorage.getItem("total_jam"),
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    convertToHMS(total) {
      let hours = Math.floor(total / 3600);
      let minutes = Math.floor((total % 3600) / 60);
      let seconds = total % 60;

      let timeString = "";
      if (hours > 0) {
        timeString += `${hours} Jam ${minutes} Menit ${seconds} Detik`;
      } else if (minutes > 0) {
        timeString += `${minutes} Menit, ${seconds} Detik`;
      } else {
        timeString += `${seconds} Detik`;
      }

      return timeString;
    },
    showModalsBs(autono) {
      //   console.log(autono);
      this.showModals = true;
      try {
        axios
          .post(
            this.UrlApi + "listeo/detil",
            {
              autono: autono,
            },
            { headers: this.headers }
          )
          .then((res) => {
            this.editValue.autono = res.data.data.autono;
            this.editValue.tanggal = res.data.data.tanggal;
            this.editValue.jam_mulai = res.data.data.jam_mulai;
            this.editValue.jam_selesai = res.data.data.jam_selesai;
            this.editValue.ket = res.data.data.ket;
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
            this.empty = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    hideModalsBs() {
      this.showModals = false;
    },
    hitungSelisih(startTime, endTime) {
      let start = moment(startTime, "HH:mm:ss");
      let end = moment(endTime, "HH:mm:ss");

      // Jika waktu akhir lebih awal dari waktu mulai, tambahkan satu hari ke waktu akhir
      if (end.isBefore(start)) {
        end.add(1, "day");
      }

      // Hitung selisih waktu dalam durasi
      let duration = moment.duration(end.diff(start));

      // Ekstrak jam dan menit dari durasi
      let hours = Math.floor(duration.asHours());
      let minutes = duration.minutes();

      return `${hours} Jam ${minutes} menit`;
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listeo?page=" + pages,
            {
              id_pegawai: localStorage.getItem("id"),
            },
            {
              headers: this.headers,
            }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              if (res.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(this.listOfeo, res.data.data);
              } else {
                console.log(res.data.data.length);
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    updateData(autono) {
      try {
        axios
          .post(
            this.UrlApi + "listeo/perbarui",
            {
              autono: autono,
              jam_mulai: this.editValue.jam_mulai,
              jam_selesai: this.editValue.jam_selesai,
              id_pegawai: localStorage.getItem("id"),
            },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              Swal.fire({
                icon: "success",
                title: "",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });

              localStorage.setItem("total_jam", res.data.data);
              const pesan =
                "*Pengajuan Overtime* :" +
                "\n" +
                "*Nama* : " +
                "\n" +
                localStorage.getItem("nama") +
                "\n" +
                "*Tanggal* : " +
                "\n" +
                this.editValue.tanggal +
                "\n" +
                "*Jam Mulai* : " +
                "\n" +
                this.editValue.jam_mulai +
                "\n" +
                "*Jam Selesai* : " +
                "\n" +
                this.editValue.jam_selesai +
                "\n" +
                "*Keterangan* : " +
                "\n" +
                this.editValue.ket +
                "\n" +
                "\n" +
                "*WAKTU OVERTIME* :" +
                "\n" +
                this.hitungSelisih(
                  this.editValue.jam_mulai,
                  this.editValue.jam_selesai
                );
              //kirim ke group
              try {
                axios
                  .post(this.UrlApi + "postwa", {
                    url: "group/sendmessage/HRD info",
                    message: pesan,
                  })
                  .then(() => {
                    // console.log(res);
                  });
              } catch (error) {
                console.log(error);
              }
              // axios
              //   .post(
              //     this.UrlApi + "pegawai/totaleoperid",
              //     {
              //       id_pegawai: localStorage.getItem("id"),
              //     },
              //     { headers: this.headers }
              //   )
              //   .then((res) => {
              //     console.log(res);
              //   });
              this.hideModalsBs();
              //   window.location.reload();
              this.loadData();
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
            this.empty = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    loadData() {
      try {
        axios
          .post(
            this.UrlApi + "listeo?page=",
            {
              id_pegawai: localStorage.getItem("id"),
            },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.dataEmpty = res.data.data.length > 0 ? true : false;
              this.listOfeo = res.data.data;
              // console.log(res.data.data);
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    this.loadData();
  },
};
</script>
