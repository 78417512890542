<template>
  <div class="masuk">
    <BackSection :menu="name" />
    <div class="container-fluid py-2">
      <CardEntitasVue />
    </div>
  </div>
</template>
<script>
import CardEntitasVue from "@/components/CardEntitas.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "List Internal Office",
    };
  },
  components: {
    BackSection,
    CardEntitasVue,
  },
};
</script>
