<template>
  <div class="lstIzin">
    <div class="col-12">
      <div class="list-group">
        <div v-for="item in listOfizin" :key="item" class="py-1 px-2">
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ item.nama_cuti }}</h5>
              <!-- <p class="d-none">
                {{ (listTglCuti = item.tanggal_cuti.split(",")) }}
              </p> -->
              <small class="text-muted">{{ item.created_on }}</small>
            </div>
            <p class="mb-1">
              Cuti Dipotong : <strong>{{ item.total_cuti }}</strong>
            </p>
            <p class="mb-1">Tanggal :</p>
            <p class="mb-1">
              {{ splitedDate(item.tanggal_cuti) }}
            </p>
            <!-- <p class="mb-1" v-for="(list, index) in listTglCuti" :key="list">
              {{ index + 1 + ". " + list }}
            </p> -->
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfizin: [],
      listTglCuti: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      tglsaatini: new Date().toISOString().slice(0, 10),
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "cutibersama?page=" + pages,
            {
              id: this.id,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.dataEmpty = res.data.data.data.length > 0 ? true : false;
              if (res.data.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(this.listOfizin, res.data.data.data);
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    splitedDate(date) {
      return date.split(",");
    },
  },
  created() {
    try {
      axios
        .post(
          this.UrlApi + "cutibersama?page=",
          {
            id: this.id,
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.dataEmpty = res.data.data.data.length > 0 ? true : false;
            this.listOfizin = res.data.data.data;
            // console.log(res.data.data.data);

            // const refMap = {};
            // this.listOfizin.forEach((element) => {
            //   this.listTglCuti = element.tanggal_cuti;
            //   cuti = element.tanggal_cuti.split(",");
            //   this.listTglCuti.push(cuti);
            //   this.listTglCuti = cuti.map((x) => {
            //     return x;
            //   });
            // });
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
