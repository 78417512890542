<template>
  <back-section :menu="nama" />
  <list-ijin-approve />
</template>
<script>
import ListIjinApprove from "@/components/Approval/ListDataIjinApprove.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Approval Ijin",
    };
  },
  components: { BackSection, ListIjinApprove },
};
</script>
