import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/custom.css";
import socket from "@/services/socketServer";
// import firebase from "@/services/firebaseInit";
// import axios from "axios";

const app = createApp(App);

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("key");
  console.log(!token, to.name);
  if (!token && to.name != "login") {
    next({ name: "login" });
  } else {
    next();
  }
});
// var token = localStorage.getItem("firebaseToken");
// setInterval(() => {
//   const currentTime = new Date();
//   const hours = currentTime.getHours();
//   const minutes = currentTime.getMinutes();
//   const seconds = currentTime.getSeconds();

//   // Cek apakah waktu adalah jam 9 pagi
//   console.log("check...");
//   if (hours === 11 && minutes === 33 && seconds === 0) {
//     try {
//       axios
//         .post(
//           "https://fcm.googleapis.com/fcm/send",
//           {
//             to: token,
//             notification: {
//               body: "Jangan lupa untuk absen masuk hari ini",
//               title: "Absen Masuk",
//               icon: "/vtagroup.png",
//             },
//           },
//           {
//             headers: {
//               "Content-Type": "application/json",
//               Authorization:
//                 "key=AAAA8MYU5dU:APA91bE4udM3VgHg4dHbYwOy-MrVEGBOvcQFtKW-0x5oPazHypLgwJbvW-Hl8QzMLhBJoaYxt4BwFDI-msNIJeB975vcfreTQ99SfJWBi2ft6dBb1eiOzUMJWP2FOdsBLOsjj3-7lX6u",
//             },
//           }
//         )
//         .then((res) => {
//           console.log(res);
//         });
//     } catch (error) {
//       console.log(error);
//     }
//   }
// }, 1000);
// axios
//   .get("https://api.vitech.asia/api/informasi")
//   .then((res) => {
//     console.log(res);
//   })
//   .catch(() => {
//     console.log("Error");
//     router.push("/home-lite");
//   });

// const db = firebase.db;
// global variable
app.mixin({
  data() {
    return {
      UrlApi: "https://api.vitech.asia/api/",
      showModals: false,
      socket: socket,
      UrlWa: "http://103.78.98.60:8086/",
      // db: db,
    };
    // return { UrlApi: "http://localhost:8000/api/" };
  },
});
app.use(router).mount("#app");
