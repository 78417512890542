<template>
  <back-section :menu="nama" :btn="btn" />
  <ListDataLiburEo />
</template>
<script>
import ListDataLiburEo from "@/components/ListDataLiburEo.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Pengajuan Extra Off",
      btn: true,
    };
  },
  components: { ListDataLiburEo, BackSection },
};
</script>
