<template>
  <back-section :menu="nama" />
  <list-data-izin-pending />
</template>
<script>
import BackSection from "@/components/BackSection.vue";
import ListDataIzinPending from "@/components/ListDataIzinPending.vue";
export default {
  setup() {
    return {
      nama: "List Izin - Pending",
    };
  },
  components: { ListDataIzinPending, BackSection },
};
</script>
