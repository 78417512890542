<template>
  <div class="container py-5" style="height: 600px">
    <div class="" style="height: 100%">
      <img
        src="../../../public/img/home/mainteni.gif"
        alt="cek"
        class=""
        style="width: 300px"
      />
      <div class="row mt-3 g-2 text-center">
        <div class="col-6">
          <a href="/absen-masuk" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../../public/img/home/masuk.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <span class="badge bg-danger my-3">Absen Masuk</span>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="/absen-keluar" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../../public/img/home/keluar.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <span class="badge bg-danger my-3">Absen Keluar</span>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import FormAbsenKeluar from "@/components/FormAbsenKeluar.vue";
// import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Dashboard",
    };
  },
  components: {
    // BackSection,
    // FormAbsenKeluar,
  },
};
</script>
