<template>
  <div class="masuk">
    <BackSection :menu="name" />
    <div class="container-fluid py-2">
      <CardOpsiAbsen />
      <!-- <CardEntitasVue /> -->
    </div>
  </div>
</template>
<script>
// import CardEntitasVue from "@/components/CardEntitas.vue";
import BackSection from "@/components/BackSection.vue";
import CardOpsiAbsen from "@/components/CardOpsiAbsen.vue";
export default {
  setup() {
    return {
      name: "Absen Masuk",
    };
  },
  components: {
    BackSection,
    CardOpsiAbsen,
    // CardEntitasVue,
  },
};
</script>
