<template>
  <back-section :menu="nama" />
  <ListDataApprove />
</template>
<script>
import ListDataApprove from "@/components/Approval/ListDataLiburEoApprove.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Approval Extra Off",
    };
  },
  components: { BackSection, ListDataApprove },
};
</script>
