<template>
  <div class="lstSakit">
    <div class="col-12 py-3">
      <div class="list-group">
        <div v-for="item in listOfizin" :key="item.autono" class="py-1 px-2">
          <a href="#" class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Pengajuan Izin</h5>
              <!-- <small class="text-muted">{{ item.created_on }}</small> -->
            </div>
            <p class="mb-0">Dari : {{ formatDate(item.tanggal_awal) }}</p>
            <p class="mb-0">Sampai : {{ formatDate(item.tanggal_akhir) }}</p>
            <p class="mb-1">Ket : {{ item.keterangan }}</p>
            <small class="text-muted" v-if="item.status == 0">Pending</small>
            <small class="text-success" v-else-if="item.status == 1"
              >Disetujui</small
            >
            <small class="text-danger" v-else>Tidak Disetujui</small>
          </a>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfizin: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listizin/tidaksetuju?page=" + pages,
            {
              id: this.id,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(this.listOfizin, res.data.data);
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    try {
      axios
        .post(
          this.UrlApi + "listizin/tidaksetuju?page=",
          {
            id: this.id,
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.dataEmpty = res.data.data.length > 0 ? true : false;
            this.listOfizin = res.data.data;
            // console.log(res.data.data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
