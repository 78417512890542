<template>
  <div class="dtlProgress">
    <button
      class="btn btn-danger btn-sm float-start my-3 mx-2"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#timeabsenpulang"
      @click.prevent="showModalsBs('', 'tambah')"
    >
      Tambah Data
    </button>
    <br />
    <div class="col-12 py-5">
      <div class="list-group">
        <div v-for="item in dataView" :key="item.autono" class="py-1 px-2">
          <a class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">{{ item.judul }}</h5>
              <div class="d-flex w-50 justify-content-end">
                <button
                  class="btn btn-warning btn-sm float-end mx-2"
                  type="button"
                  @click.prevent="showModalsBs(item.autono, 'edit')"
                >
                  Ubah Data
                </button>
                <button
                  class="btn btn-danger btn-sm float-end"
                  type="button"
                  @click.prevent="hapus(item.autono)"
                >
                  Hapus
                </button>
              </div>
            </div>
            <p class="mb-0">Progres : {{ item.progres }}</p>
            <p class="mb-0">Biaya : {{ item.biaya }}</p>
            <p class="mb-0">Tanggal Upload : {{ item.tanggal_upload }}</p>
            <p class="mb-0">Ket : {{ item.keterangan }}</p>
          </a>
        </div>
      </div>
    </div>
    <div
      class="modal fade"
      id="timeabsenpulang"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-labelledby="timeabsenpulangLabel"
      aria-hidden="true"
      :class="{ 'show d-block mt-0 px-0': showModals, '': !showModals }"
      v-bind:style="showModals ? 'background-color: #0000009c' : ''"
      v-bind:role="showModals ? 'dialog' : ''"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="timeabsenpulangLabel">
              {{ labelButton }}
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click.prevent="hideModalsBs()"
            ></button>
          </div>
          <div class="overlay" v-show="loadingProg == true">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
          <div class="modal-body">
            <form @submit.prevent="updateData(status)" ref="fEditable">
              <dl class="row g-2 py-3">
                <dt>Tanggal :</dt>
                <dd>
                  <input
                    type="date"
                    class="form-control"
                    v-model="tanggal"
                    required
                    :readonly="readonly"
                    :min="lastTglUpload"
                  />
                </dd>
                <dt>Judul Kegiatan :</dt>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    v-model="judul"
                    required
                  />
                </dd>
                <dt>Tipe Dokumen</dt>
                <select class="form-control" v-model="selectedDokumen" required>
                  <option disabled>Pilih Tipe Dokumen</option>
                  <option
                    v-for="dok in listTipeDokumen"
                    :key="dok.autocode"
                    :value="dok.autocode"
                  >
                    {{ dok.nama_dokumen }}
                  </option>
                </select>
                <dt>Upload File :</dt>
                <input
                  type="file"
                  ref="file"
                  class="form-control"
                  @change="onChange"
                />
                <small>format file : <code>jpg, png, jpeg</code></small>
                <dt>Progres Seluruh Kegiatan (%) :</dt>
                <dd>
                  <input
                    type="number"
                    class="form-control"
                    v-model="progres"
                    max="100"
                    :min="lastProgres"
                    :readonly="readonly"
                    required
                  />
                </dd>
                <dt>Biaya :</dt>
                <dd>
                  <input
                    type="text"
                    class="form-control"
                    v-model="biaya"
                    required
                  />
                </dd>
                <dt>Keterangan :</dt>
                <dd>
                  <textarea
                    class="form-control"
                    v-model="keterangan"
                  ></textarea>
                </dd>
              </dl>
              <button type="submit" class="btn btn-primary mt-1 btn-block">
                Simpan
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import imageCompressor from "./Compressor/vue-image-compressor.vue";
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};

export default {
  data() {
    return {
      uppercase: atob(this.$route.query.q),
      ptp: atob(this.$route.query.r),
      autono: atob(this.$route.query.s),
      dataView: [],
      editView: [],
      empty: true,
      editKegiatan: this.$el,
      id_report: "",
      tanggal: "",
      judul: "",
      progres: 0,
      biaya: 0,
      keterangan: "",
      readonly: false,
      status: "",
      file: "",
      listTipeDokumen: [],
      selectedDokumen: {},
      lastProgres: 0,
      lastTglUpload: "",
      labelButton: "Tambah Data",
      loadingProg: false,
      base64: null,
      filename: null,
      mimetype: null,
      ukuran: null,
      compressed: {},
      original: {},
      result: {},
      reader: {},
    };
  },
  // components: {
  //   imageCompressor,
  // },
  methods: {
    showModalsBs(autono, status) {
      console.log(this.uppercase);
      this.showModals = true;

      if (status == "tambah") {
        this.labelButton = "Tambah Data";
        this.$refs.fEditable.reset();
        this.readonly = false;
        this.status = "tambah";
        this.lastData();
      } else {
        this.readonly = true;
        this.status = "edit";
        this.labelButton = "Ubah Data ?";
        try {
          axios
            .post(
              this.UrlApi + "listaktproyek/edit",
              {
                id: autono,
              },
              { headers: headers }
            )
            .then((res) => {
              if (res.data.result !== null) {
                this.empty = res.data.result.length > 0 ? false : true;
                this.id_report = res.data.result.autono;
                this.tanggal = res.data.result.tanggal_upload;
                this.judul = res.data.result.judul;
                this.progres = res.data.result.progres;
                this.biaya = res.data.result.biaya;
                this.keterangan = res.data.result.keterangan;
                this.selectedDokumen = res.data.result.id_tipe_dokumen;
              } else {
                this.empty = true;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: "",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response");
              } else if (err.request) {
                console.log("error request");
              } else {
                console.log("error");
              }
              this.empty = true;
            });
        } catch (error) {
          console.log(error);
        }
      }
    },
    hideModalsBs() {
      this.showModals = false;
      window.location.reload();
    },
    updateData(status) {
      if (status == "tambah") {
        this.loadingProg = true;
        try {
          axios
            .post(
              this.UrlApi + "listaktproyek/savebase",
              {
                tanggal_upload: this.tanggal,
                progres: this.progres,
                judul: this.judul,
                biaya: this.biaya,
                keterangan: this.keterangan,
                username: localStorage.getItem("nama"),
                id_aktifitas_proyek: this.uppercase,
                id_penetapan_proyek: this.ptp,
                id_tipe_dokumen: this.selectedDokumen,
                file: this.compressed,
                filename: this.filename,
                mimetype: this.mimetype,
                size: this.ukuran,
              },
              { headers: headers }
            )
            .then((res) => {
              // console.log(res);
              if (res.data.result !== null) {
                this.loadingProg = false;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil!",
                  text: "Data berhasil disimpan",
                  showConfirmButton: false,
                  timer: 1500,
                });
                window.location.reload();
              } else {
                this.loadingProg = false;
                this.empty = true;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: "",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log(err.response);
                this.loadingProg = false;
              } else if (err.request) {
                console.log(err.request);
                this.loadingProg = false;
              } else {
                console.log("error");
                this.loadingProg = false;
              }
              this.empty = true;
            });
        } catch (error) {
          console.log(error);
          this.loadingProg = false;
          Swal.fire({
            icon: "error",
            title: "Gagal!",
            text: "",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } else {
        this.loadingProg = true;
        try {
          axios
            .post(
              this.UrlApi + "listaktproyek/update",
              {
                id: this.id_report,
                judul: this.judul,
                biaya: this.biaya,
                keterangan: this.keterangan,
              },
              { headers: headers }
            )
            .then((res) => {
              if (res.data.result !== null) {
                this.loadingProg = false;
                window.location.reload();
              } else {
                this.loadingProg = false;
                this.empty = true;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: "",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response");
                this.loadingProg = false;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: "",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else if (err.request) {
                console.log("error request");
                this.loadingProg = false;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: "Ukuran gambar tidak boleh lebih dari 1 Mb",
                  showConfirmButton: false,
                  timer: 1500,
                });
              } else {
                console.log("error");
                this.loadingProg = false;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: "",
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
              this.empty = true;
            });
        } catch (error) {
          console.log(error);
          this.loadingProg = false;
          Swal.fire({
            icon: "error",
            title: "Gagal!",
            text: "",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    hapus(autono) {
      try {
        axios
          .post(
            this.UrlApi + "listaktproyek/hapus",
            {
              id: autono,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.result !== null) {
              window.location.reload();
            } else {
              this.empty = true;
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: "",
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
            this.empty = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    // onChnageFileUpload() {
    //   this.file = this.$refs.file.files[0];
    //   this.ukuran = this.$refs.file.files[0].size;
    //   this.filename = this.$refs.file.files[0].name;
    //   this.mimetype = this.$refs.file.files[0].type;
    //   // this.createBase64Image(this.file);
    //   console.log(this.file);
    // },
    upload() {
      let compressor = this.$refs.compressor.$el;
      compressor.click();
    },
    createBase64Image(FileObject) {
      const reader = new FileReader();
      reader.onload = (event) => {
        var img64 = event.target.result;
        var splited = img64.split(",");
        this.base64 = splited[1];
      };
      reader.readAsDataURL(FileObject);
    },
    getFiles(obj) {
      // console.log("get files");
      this.file = obj.compressed;
      this.original = obj.original;
      // const b = a.size.replace("kB", " ");
      // this.original = parseInt(b);
      this.compressed = obj.compressed.base64;
      this.mimetype = obj.compressed.type;
      this.filename = obj.compressed.name;
      this.ukuran = obj.compressed.file.size;
      // console.log(this.compressed);
      // this.quality = 50;
      // console.log(parseInt(b));
    },
    lastData() {
      try {
        axios
          .post(
            this.UrlApi + "lastdataaktifitas",
            {
              parent_id: this.uppercase,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.result !== null) {
              this.lastProgres = res.data[0].progres;
              this.lastTglUpload = res.data[0].tanggal_upload;
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
            this.empty = true;
          });
      } catch (error) {
        console.log(error);
      }
    },
    //proses compress

    //jika input file berubah
    onChange(e) {
      //jiksa tidak ada file yang dipilih
      let file = e.target.files[0];
      if (!file) return false;

      //get file
      this.file = e.target.files[0];

      //validasi tidak boleh selain gambar
      let type = this.file.type;
      let valid = type.indexOf("image") !== -1;

      if (!valid)
        throw Swal.fire({
          icon: "error",
          title: "Gagal!",
          text: "Format file harus jpg, png, dan jpeg",
          showConfirmButton: false,
          timer: 1500,
        });

      //buat FileReader
      this.reader = new FileReader();

      //Convert file ke base64 text
      this.reader.readAsDataURL(this.file);

      //on reader load
      this.reader.onload = this.fileOnLoad;
    },

    //ketika file sudah dimuat sepenuhnya
    fileOnLoad() {
      let { file } = this;

      // buat fileInfo Object
      let fileInfo = {
        name: file.name,
        type: file.type,
        size: Math.round(file.size / 1000) + " kB",
        base64: this.reader.result,
        file: file,
      };

      this.result = fileInfo;
      // DrawImage
      this.drawImage(this.result.base64);
    },

    // Draw and compress the image
    drawImage(imgUrl) {
      // Recreate canvas element
      let canvas = document.createElement("canvas");

      //set canvas context
      let ctx = canvas.getContext("2d");

      // Create new image
      let img = new Image();
      img.onload = () => {
        // console.log(img.width, img.height);
        // Image size after sacling
        let scale = 45 / 100;
        let width = img.width * scale;
        let height = img.height * scale;
        // set canvas height and width berdasarkan ukuran dan scale gambar
        canvas.setAttribute("width", width);
        canvas.setAttribute("height", height);

        ctx.drawImage(img, 0, 0, width, height);

        // Quality of image
        let quality = 45 / 100;

        // jika file sudah di proses
        let base64 = canvas.toDataURL(this.file.type, quality);
        let splitedType = this.file.type.split("/");
        let fileName = this.result.file.name;
        let lastDot = fileName.lastIndexOf(".");
        fileName = fileName.substr(0, lastDot) + "." + splitedType[1];

        let objToPass = {
          canvas: canvas,
          original: this.result,
          compressed: {
            // blob: this.toBlob(base64),
            base64: base64,
            name: fileName,
            file: this.buildFile(base64, fileName),
          },
        };

        objToPass.compressed.size =
          Math.round(objToPass.compressed.file.size / 1000) + " kB";
        objToPass.compressed.type = this.file.type;
        // console.log("done", objToPass.compressed);

        this.compressed = objToPass.compressed.base64;
        this.mimetype = objToPass.compressed.type;
        this.filename = objToPass.compressed.name;
        this.ukuran = objToPass.compressed.file.size;
      };

      img.src = imgUrl;
    },
    //conver blob to file
    buildFile(blob, name) {
      return new File([blob], name);
    },
  },
  created() {
    try {
      axios
        .post(
          this.UrlApi + "listaktproyek/detil",
          {
            autocode: this.uppercase,
          },
          { headers: headers }
        )
        .then((res) => {
          // console.log(res.data.result.data);
          if (res.data.result !== null) {
            this.empty = res.data.result.data.length > 0 ? false : true;
            this.dataView = res.data.result.data;
          } else {
            this.empty = true;
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: "",
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
          this.empty = true;
        });
    } catch (error) {
      console.log(error);
    }

    try {
      axios
        .get(this.UrlApi + "tipedokumen", { headers: headers })
        .then((res) => {
          this.listTipeDokumen = res.data.result;
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
          this.empty = true;
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
