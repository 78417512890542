<template>
  <div class="entiti">
    <div class="col-12" :class="{ 'd-none': absenMasuk }">
      <div class="list-group">
        <div v-if="loadingProg">
          <div class="overlay">
            <div class="d-flex justify-content-center">
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </div>
          </div>
        </div>

        <div v-else class="m-2">
          <!-- <dt>Jam Masuk</dt>
          <dd>
            <input
              type="time"
              class="form-control mb-3"
              :value="jamMasuk"
              @change="onChange($event.target.value)"
            />
          </dd> -->
          <dt>Pilih Site :</dt>
          <dd>
            <div
              v-for="(item, index) in dataEntitas"
              :key="index"
              class="card my-3"
            >
              <div class="row">
                <div class="col-md-4">
                  <div class="card-header bg-light">
                    <img
                      :src="img + item.nama_file"
                      alt="cek"
                      class="mx-auto d-block"
                      width="120"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="card-body">
                    <h6 class="card-title">{{ item.nama_perusahaan }}</h6>
                    <button
                      type="button"
                      class="card-text btn btn-primary"
                      @mousedown="saveLoc($event)"
                      :data-lonlat="item.lonLat"
                      :data-entt="item.autono"
                      :data-note="item.nama_perusahaan"
                      style="border-radius: 10px"
                    >
                      Masuk
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </dd>
        </div>
      </div>
    </div>
    <div
      class="col-12 d-flex justify-content-center text-center"
      :class="{ 'd-none': !absenMasuk }"
    >
      <div class="row py-5">
        <img
          src="../../public/img/home/cek.gif"
          alt="cek"
          class="mx-auto d-block py-2"
          style="width: 100px"
        />
        <h5 class="text-success">{{ nama }}</h5>
        <h3>Anda Sudah Melakukan Absen Masuk</h3>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import { Geolocation } from "@capacitor/geolocation";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
var minRad = 500; // meter
export default {
  data() {
    return {
      dataEntitas: [],
      nama: localStorage.nama,
      geoloc: "0,0",
      absenMasuk: localStorage.masuk,
      timer: null,
      loadingProg: false,
      jamMasuk: "",
      img: "https://api.vitech.asia/storage/entitas/",
    };
  },
  created() {
    this.update();
    var time = new Date().toTimeString().split(" ")[0];
    var splitTime = time.split(":");
    var newTime = splitTime[0] + ":" + splitTime[1];
    this.jamMasuk = newTime;
    let dataEntt = JSON.parse(localStorage.getItem("entitas"));
    if (dataEntt === null && navigator.onLine) {
      axios
        .get(this.UrlApi + "getlocentitas", { headers: headers })
        .then((res) => {
          // console.log(res.data.data);
          this.dataEntitas = res.data.data;
          localStorage.setItem("entitas", JSON.stringify(res.data.data));
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } else if (dataEntt !== null && (navigator.onLine || !navigator.onLine)) {
      this.dataEntitas = dataEntt;
    } else {
      this.dataEntitas = dataEntt;
    }
    this.timer = setInterval(() => {
      this.absenMasuk = localStorage.getItem("masuk") !== null ? true : false;
    }, 1000);
  },
  methods: {
    async currentPosition() {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      // console.log("Current Position", coordinates.coords);
      fetch(
        "https://nominatim.openstreetmap.org/search.php?q=" +
          coordinates.coords.latitude +
          "," +
          coordinates.coords.longitude +
          "&polygon_geojson=1&format=json"
      )
        .then((response) => response.json())
        .then((j) => {
          this.address = j[0].display_name;
          localStorage.setItem("address", this.address);
          localStorage.setItem("lon", j[0].lon);
          localStorage.setItem("lat", j[0].lat);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Lokasi berhasil di update",
            showConfirmButton: false,
            timer: 1500,
          });
          this.updating = false;
        })
        .catch((error) => {
          this.updating = false;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              Swal.fire({
                icon: "warning",
                title: "Request Timeout",
                text: "Mohon tunggu beberapa menit, lalu coba perbarui lokasi lagi...",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok!",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigator.geolocation;
                }
              });
              break;
            case error.UNKNOWN_ERROR:
              console.log("An unknown error occurred.");
              break;
          }
        });
    },
    update() {
      Swal.fire({
        icon: "info",
        title: "Memperbarui Lokasi",
        text: "Mohon Tunggu Sebentar...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      this.updating = true;
      this.currentPosition();
    },
    saveLoc(event) {
      this.loadingProg = true;
      let absenLoc = event.target.getAttribute("data-lonlat");
      let absenEntt = event.target.getAttribute("data-entt");
      let absenNote = event.target.getAttribute("data-note");
      let urls = this.UrlApi;
      this.checkRadius(absenLoc, absenEntt, urls, absenNote);
    },
    checkRadius(dat, identittas, urls, absenNote) {
      var self = this;
      let pecahdata = dat.split(",");
      let idEntities = parseInt(identittas);
      let lat = localStorage.getItem("lat");
      let lon = localStorage.getItem("lon");

      var R = 3958.8; // Radius bumi dalam mils
      var rlat1 = lat * (Math.PI / 180); // Convert degrees to radians
      var rlat2 = parseFloat(pecahdata[1]) * (Math.PI / 180); // Convert degrees to radians
      var difflat = rlat2 - rlat1; // Radian difference (latitudes)
      var difflon = (parseFloat(pecahdata[0]) - lon) * (Math.PI / 180); // Radian difference (longitudes)

      var d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
          )
        );
      let miles = d;
      let kilometers = miles * 1.609344;
      let meters = kilometers * 1000;
      let Dt = new Date();
      let Hrs = Dt.getHours();
      let Mnt = Dt.getMinutes();
      let Tm = (Hrs + ":" + Mnt).toString();
      const offset = Dt.getTimezoneOffset();
      Dt = new Date(Dt.getTime() - offset * 60 * 1000);
      let nowDates = Dt.toJSON().slice(0, 10).replace(/-/g, "/").toString();
      if (parseFloat(meters.toFixed(3)) < minRad) {
        axios
          .post(
            urls + "absenmasuk",
            {
              id_entitas: idEntities,
              id_pegawai: parseInt(localStorage.getItem("id")),
              tanggal_absensi: nowDates,
              jam_masuk: Tm,
              keterangan: localStorage.address,
              note: absenNote,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              Swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              self.loadingProg = false;
              localStorage.setItem(
                "masuk",
                JSON.stringify([
                  {
                    masuk: true,
                    absen: res.data.data[0]["absensi"],
                    keluar: false,
                    tanggal: res.data.data[0]["tanggal"],
                  },
                ])
              );
            } else {
              Swal.fire({
                icon: "success",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              self.loadingProg = false;
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
              self.loadingProg = false;
            } else if (err.request) {
              console.log("error request");
              self.loadingProg = false;
            } else {
              console.log("error");
              self.loadingProg = false;
            }
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops!!",
          text: "Maaf Lokasi Anda Masih Jauh dari Kantor!!",
          showConfirmButton: false,
          timer: 2000,
        });
        self.loadingProg = false;
      }
      // console.log(parseFloat(meters.toFixed(3)), kilometers.toFixed(3));
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(
      //     function () {},
      //     function () {},
      //     {}
      //   );

      //   navigator.geolocation.getCurrentPosition(
      //     function (position) {
      //       var R = 3958.8; // Radius bumi dalam mils
      //       var rlat1 = position.coords.latitude * (Math.PI / 180); // Convert degrees to radians
      //       var rlat2 = parseFloat(pecahdata[1]) * (Math.PI / 180); // Convert degrees to radians
      //       var difflat = rlat2 - rlat1; // Radian difference (latitudes)
      //       var difflon =
      //         (parseFloat(pecahdata[0]) - position.coords.longitude) *
      //         (Math.PI / 180); // Radian difference (longitudes)

      //       var d =
      //         2 *
      //         R *
      //         Math.asin(
      //           Math.sqrt(
      //             Math.sin(difflat / 2) * Math.sin(difflat / 2) +
      //               Math.cos(rlat1) *
      //                 Math.cos(rlat2) *
      //                 Math.sin(difflon / 2) *
      //                 Math.sin(difflon / 2)
      //           )
      //         );
      //       let miles = d;
      //       let kilometers = miles * 1.609344;
      //       let meters = kilometers * 1000;
      //       let Dt = new Date();
      //       // let Hrs = Dt.getHours();
      //       // let Mnt = Dt.getMinutes();
      //       // let Tm = (Hrs + ":" + Mnt).toString();
      //       let nowDates = Dt.toJSON()
      //         .slice(0, 10)
      //         .replace(/-/g, "/")
      //         .toString();
      //       if (parseFloat(meters.toFixed(3)) < minRad) {
      //         axios
      //           .post(
      //             urls + "absenmasuk",
      //             {
      //               id_entitas: idEntities,
      //               id_pegawai: parseInt(localStorage.getItem("id")),
      //               tanggal_absensi: nowDates,
      //               jam_masuk: self.jamMasuk,
      //               keterangan: localStorage.address,
      //               note: absenNote,
      //             },
      //             { headers: headers }
      //           )
      //           .then((res) => {
      //             if (res.data.success && res.data.success !== null) {
      //               Swal.fire({
      //                 icon: "success",
      //                 title: "Berhasil!",
      //                 text: res.data.message,
      //                 showConfirmButton: false,
      //                 timer: 1500,
      //               });
      //               self.loadingProg = false;
      //               localStorage.setItem(
      //                 "masuk",
      //                 JSON.stringify([
      //                   {
      //                     masuk: true,
      //                     absen: res.data.data[0]["absensi"],
      //                     keluar: false,
      //                     tanggal: res.data.data[0]["tanggal"],
      //                   },
      //                 ])
      //               );
      //             } else {
      //               Swal.fire({
      //                 icon: "success",
      //                 title: "Gagal!",
      //                 text: res.data.message,
      //                 showConfirmButton: false,
      //                 timer: 1500,
      //               });
      //               self.loadingProg = false;
      //             }
      //           })
      //           .catch((err) => {
      //             if (err.response) {
      //               console.log("error response");
      //               self.loadingProg = false;
      //             } else if (err.request) {
      //               console.log("error request");
      //               self.loadingProg = false;
      //             } else {
      //               console.log("error");
      //               self.loadingProg = false;
      //             }
      //           });
      //       } else {
      //         Swal.fire({
      //           icon: "error",
      //           title: "Oops!!",
      //           text: "Maaf Lokasi Anda Masih Jauh dari Kantor!!",
      //           showConfirmButton: false,
      //           timer: 2000,
      //         });
      //         self.loadingProg = false;
      //       }
      //       console.log(parseFloat(meters.toFixed(3)), kilometers.toFixed(3));
      //     },
      //     function (error) {
      //       console.log(error.message);
      //       self.loadingProg = false;
      //     },
      //     {
      //       enableHighAccuracy: true,
      //     }
      //   );
      // } else {
      //   alert("Geolocation is not supported by this browser.");
      // }
    },
    onChange(event) {
      this.jamMasuk = event;
    },
  },
  beforeUnmount() {
    clearInterval(this.timer);
  },
};
</script>
<style>
.bg-header {
  background: #bacddb;
}
</style>
