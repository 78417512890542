<template>
  <div class="lsAbs">
    <BackSection :menu="name" />
    <div class="container-fluid py-2">
      <DataAbsensiPegawai />
    </div>
  </div>
</template>
<script>
import DataAbsensiPegawai from "@/components/DataAbsensiPegawai.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Daftar Absensi Pegawai",
    };
  },
  components: {
    BackSection,
    DataAbsensiPegawai,
  },
};
</script>
