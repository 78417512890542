<template>
  <back-section :menu="nama" />
  <list-data-izin />
</template>
<script>
import ListDataIzin from "@/components/ListDataIzin.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Izin",
    };
  },
  components: { ListDataIzin, BackSection },
};
</script>
