<!-- eslint-disable vue/no-parsing-error -->
<template>
  <div class="home">
    <NavSection />
    <div class="container-fluid pb-3">
      <div class="header">
        <div class="col-12 p-5 pt-5">
          <div class="card top-40 text-start">
            <div class="card-body">
              <div class="row">
                <div class="col-4">
                  <img
                    src="../../public/img/home/user.png"
                    alt=""
                    class="img-thumbnail img-rounded mx-auto d-block"
                  />
                </div>
                <div class="col-8">
                  <h6 class="mb-0 text-truncate">{{ nama }}</h6>
                  <p class="mb-0">{{ jabatan }}</p>
                  <!-- <small class="mb-0">{{ entitas }}</small> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <InternetChecker />
      <InformasiHrd />
      <CountIzin />
      <div
        class="alert alert-info my-3"
        role="alert"
        v-if="total_jam_eo > 9 * 3600"
      >
        Anda bisa mengajukan libur EO.
        <a href="/change-libur-eo" class="alert-link"
          >klik disini untuk mengajukan libur</a
        >.
      </div>
      <!-- <NowLocation /> -->
      <div class="row mt-3">
        <div class="col-12">
          <div class="card">
            <div class="card-body">
              <!-- <h5 class="card-title"></h5> -->
              <div class="row">
                <div class="col-8">
                  <h5>Lokasi Terakhir</h5>
                </div>
                <div class="col-4" v-show="!updating">
                  <span class="badge bg-info" @click.prevent="update">
                    Perbarui Lokasi
                  </span>
                </div>
                <div class="col-4" v-show="updating">
                  <span class="badge bg-warning"> Memperbarui... </span>
                </div>
              </div>
              <p class="card-text">
                <!-- <NowLocation /> -->
                <input
                  type="text"
                  class="form-control"
                  v-model="address"
                  disabled
                />
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3 g-2 text-center">
        <div class="col-6">
          <a href="/absen-masuk" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/masuk.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Absen</small>
                <p class="card-text">Masuk</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="/ubah-lokasi" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/maps.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Ubah</small>
                <p class="card-text">Lokasi</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6" v-show="entitas == 7">
          <a href="/change-holiday" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/exchange.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Pengajuan</small>
                <p class="card-text">PH</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6" v-show="entitas == 7">
          <a href="/change-eo" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/clock.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Pengajuan</small>
                <p class="card-text">Overtime</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="/list-progress-report" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <span
                  class="badge badge-pill bg-danger badge-danger position-absolute text-white"
                  >{{ total }}</span
                >
                <img
                  src="../../public/img/home/report.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Progres</small>
                <p class="card-text">Report</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="/izin" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/izin.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Permohonan</small>
                <p class="card-text">Izin</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="/cuti" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/cuti.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Permohonan Izin</small>
                <p class="card-text">Cuti</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-6">
          <a href="/sakit" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/sakit.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <small class="card-title">Permohonan Izin</small>
                <p class="card-text">Sakit</p>
              </div>
            </div>
          </a>
        </div>
        <div class="col-3"></div>
        <div class="col-6">
          <a href="/absen-keluar" class="text-decoration-none">
            <div class="card">
              <div class="card-body">
                <img
                  src="../../public/img/home/keluar.png"
                  class="img-responsive mx-auto d-block w60"
                />
                <!-- <small class="card-title">Absen</small>
                <p class="card-text">Keluar</p> -->
                <label class="badge bg-danger">Absen Keluar</label>
              </div>
            </div>
          </a>
        </div>
        <div class="col-3"></div>
      </div>
    </div>
  </div>
</template>

<script>
// import NowLocation from "@/components/NowLocation.vue";
import InternetChecker from "@/components/InternetChecker.vue";
import NavSection from "@/components/NavSection.vue";
import CountIzin from "@/components/CountIzin.vue";
import axios from "axios";
import Swal from "sweetalert2";
import InformasiHrd from "@/components/InformasiHrd.vue";
import { Geolocation } from "@capacitor/geolocation";

export default {
  name: "HomeView",
  components: {
    // NowLocation,
    InternetChecker,
    NavSection,
    CountIzin,
    InformasiHrd,
  },
  data() {
    return {
      nama: localStorage.getItem("nama"),
      jabatan: localStorage.getItem("jabatan"),
      entitas:
        localStorage.getItem("pt") == "null" ? "-" : localStorage.getItem("pt"),
      total: 0,
      headers: "",
      lon: 0,
      lat: 0,
      address: localStorage.getItem("address"),
      updating: false,
      total_jam_eo: localStorage.getItem("total_jam"),
    };
  },
  created() {
    this.checkDate();
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    this.getLastHour();
    try {
      axios
        .post(
          this.UrlApi + "aktproyek",
          {
            id: localStorage.getItem("id"),
          },
          {
            headers: this.headers,
          }
        )
        .then((res) => {
          if (res.data != null) {
            this.total = res.data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }

    if (localStorage.getItem("lat") === null) {
      this.update();
    }

    this.getNomorPic();
  },
  methods: {
    checkDate() {
      let today = new Date();
      const offset = today.getTimezoneOffset();
      today = new Date(today.getTime() - offset * 60 * 1000);
      const masuk = localStorage.getItem("masuk");
      const tgl_masuk = JSON.parse(masuk);
      var tgls = "";
      const tgl = today.toISOString().split("T")[0];
      // console.log(tgl);
      // console.log(today.toISOString().split("T")[0]);
      // if (localStorage.getItem("masuk") !== null) {
      if (tgl_masuk !== null) {
        Object.keys(tgl_masuk).forEach((key) => {
          tgls = tgl_masuk[key].tanggal;
        });
        // if (tgl_masuk !== "") {
        if (tgls !== tgl) {
          const split_tgl = tgls.split("-");
          const newDate =
            split_tgl[2] + "-" + split_tgl[1] + "-" + split_tgl[0];
          Swal.fire({
            icon: "warning",
            title: "Info",
            html:
              "Anda lupa absen pulang pada tanggal " +
              newDate +
              ", silahkan isi dulu jam pulang dan kegiatan pada tanggal tersebut pada menu " +
              '<a href="/list-absen">List Absensi</a>',
            showConfirmButton: true,
            // timer: 3000,
          });
        }
      }
    },
    getNomorPic() {
      try {
        axios
          .post(
            this.UrlApi + "nomorpic",
            {
              id: localStorage.getItem("id"),
            },
            {
              headers: this.headers,
            }
          )
          .then((res) => {
            if (res.data != null) {
              var no = [];
              for (let i = 0; i < res.data.data[0].length; i++) {
                no.push(res.data.data[0][i]);
              }
              localStorage.setItem("nomorpic", JSON.stringify(no));
              // console.log(no);
            } else {
              console.log("error");
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getLastHour() {
      axios
        .post(
          this.UrlApi + "pegawai/totaleoperid",
          {
            id_pegawai: localStorage.getItem("id"),
          },
          {
            headers: this.headers,
          }
        )
        .then((res) => {
          localStorage.setItem("total_jam", res.data.data.total_jam_eo);
        });
    },
    async currentPosition() {
      const coordinates = await Geolocation.getCurrentPosition({
        enableHighAccuracy: true,
      });
      // console.log("Current Position", coordinates.coords);
      // this.lon = coordinates.coords.longitude;
      // this.lat = coordinates.coords.latitude;

      fetch(
        "https://nominatim.openstreetmap.org/search.php?q=" +
          coordinates.coords.latitude +
          "," +
          coordinates.coords.longitude +
          "&polygon_geojson=1&format=json"
      )
        .then((response) => response.json())
        .then((j) => {
          this.address = j[0].display_name;
          localStorage.setItem("address", this.address);
          localStorage.setItem("lon", j[0].lon);
          localStorage.setItem("lat", j[0].lat);
          Swal.fire({
            icon: "success",
            title: "Berhasil",
            text: "Lokasi berhasil di update",
            showConfirmButton: false,
            timer: 1500,
          });
          this.updating = false;
          this.checkDate();
          // window.location.reload();
        })
        .catch((error) => {
          this.updating = false;
          switch (error.code) {
            case error.PERMISSION_DENIED:
              console.log("User denied the request for Geolocation.");
              break;
            case error.POSITION_UNAVAILABLE:
              console.log("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              console.log("The request to get user location timed out.");
              Swal.fire({
                icon: "warning",
                title: "Request Timeout",
                text: "Mohon tunggu beberapa menit, lalu coba perbarui lokasi lagi...",
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: "Ok!",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  navigator.geolocation;
                }
              });
              break;
            case error.UNKNOWN_ERROR:
              console.log("An unknown error occurred.");
              break;
          }
        });
    },
    update() {
      Swal.fire({
        icon: "info",
        title: "Memperbarui Lokasi",
        text: "Mohon Tunggu Sebentar...",
        showConfirmButton: false,
        allowOutsideClick: false,
      });
      this.updating = true;
      // var self = this;
      this.currentPosition();
      // if (navigator.geolocation) {
      //   navigator.geolocation.getCurrentPosition(
      //     function (position) {
      //       self.lon = position.coords.longitude;
      //       self.lat = position.coords.latitude;
      //       fetch(
      //         "https://nominatim.openstreetmap.org/search.php?q=" +
      //           position.coords.latitude +
      //           "," +
      //           position.coords.longitude +
      //           "&polygon_geojson=1&format=json"
      //       )
      //         .then((response) => response.json())
      //         .then((j) => {
      //           self.address = j[0].display_name;
      //           localStorage.setItem("address", self.address);
      //           localStorage.setItem("lon", j[0].lon);
      //           localStorage.setItem("lat", j[0].lat);
      //           Swal.fire({
      //             icon: "success",
      //             title: "Berhasil",
      //             text: "Lokasi berhasil di update",
      //             showConfirmButton: false,
      //             timer: 1500,
      //           });
      //           self.updating = false;
      //           // window.location.reload();
      //         });
      //     },
      //     function (error) {
      //       // console.log(error.message);
      //       self.updating = false;
      //       switch (error.code) {
      //         case error.PERMISSION_DENIED:
      //           console.log("User denied the request for Geolocation.");
      //           break;
      //         case error.POSITION_UNAVAILABLE:
      //           console.log("Location information is unavailable.");
      //           break;
      //         case error.TIMEOUT:
      //           console.log("The request to get user location timed out.");
      //           Swal.fire({
      //             icon: "warning",
      //             title: "Request Timeout",
      //             text: "Mohon tunggu beberapa menit, lalu coba perbarui lokasi lagi...",
      //             confirmButtonColor: "#3085d6",
      //             cancelButtonColor: "#d33",
      //             confirmButtonText: "Ok!",
      //             allowOutsideClick: false,
      //           }).then((result) => {
      //             if (result.isConfirmed) {
      //               navigator.geolocation;
      //             }
      //           });
      //           break;
      //         case error.UNKNOWN_ERROR:
      //           console.log("An unknown error occurred.");
      //           break;
      //       }
      //     },
      //     {
      //       enableHighAccuracy: true,
      //       maximumAge: 600000,
      //       timeout: 10000,
      //       // timeout: 5000,
      //     }
      //   );
      // }
    },
  },
};
</script>
