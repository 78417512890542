import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/HomeView.vue";
import CutiView from "@/views/CutiView.vue";
import SakitView from "@/views/SakitView.vue";
import IzinView from "@/views/IzinView.vue";
import LoginView from "@/views/LoginView.vue";
import AbsenMasukView from "@/views/AbsenMasukView.vue";
import AbsenKeluarView from "@/views/AbsenKeluarView.vue";
import ListAbsenView from "@/views/ListAbsenView.vue";
import DetilAbsenView from "@/views/DetilAbsensiView.vue";
import ListIzinView from "@/views/ListIzinView.vue";
import ListIzinPendingView from "@/views/ListIzinPendingView.vue";
import ListIzinSetujuView from "@/views/ListIzinSetujuView.vue";
import ListIzinTidakSetujuView from "@/views/ListIzinTidakSetujuView.vue";
import ListSakitView from "@/views/ListSakitView.vue";
import ListCutiView from "@/views/ListCutiView.vue";
import ListCutiPendingView from "@/views/ListCutiPendingView.vue";
import ListCutiSetujuView from "@/views/ListCutiSetujuView.vue";
import ListCutiTidakSetujuView from "@/views/ListCutiTidakSetujuView.vue";
import DownloadPdfView from "@/views/DownloadPdfView.vue";
import ListProgressReportView from "@/views/ListProgressReportView.vue";
import DetilProgressReport from "@/views/DetilProgressReportView.vue";
import ListCutiApprove from "@/views/ListCutiApproveView.vue";
import ListIjinApprove from "@/views/ListIjinApproveView.vue";
import ListCutiAllView from "@/views/ListCutiAllView.vue";
import ListInternalOfficeView from "@/views/ListInternalOfficeView.vue";
import ListClientSiteView from "@/views/ListClientSiteView.vue";
import ListLainnyaView from "@/views/ListLainnyaView.vue";
import UbahLokasiView from "@/views/UbahLokasiView.vue";
import DataAbsensiPegawaiView from "@/views/DataAbsensiPegawaiView.vue";
import ListUbahLokasiView from "@/views/ListUbahLokasiView.vue";
import ListSakitAllView from "@/views/ListSakitAllView.vue";
import ListIzinAllView from "@/views/ListIzinAllView.vue";
import FAQView from "@/views/FAQView.vue";
import ListCutiBersamaView from "@/views/ListCutiBersamaView.vue";
import DataPicMonitoringView from "@/views/DataPicMonitoringView.vue";
import ListPenugasanTimTeknis from "@/views/ListPenugasanTimTeknis.vue";
import ChangeHolidayView from "@/views/ChangeHolidayView.vue";
//error handle view
import HomeLite from "@/views/errorHandle/HomeLite.vue";
import ListPhApproveView from "@/views/ListPhApproveView.vue";
import ListPegawaiPhAllView from "@/views/ListPegawaiPhAllView.vue";
import ChangeEoView from "@/views/ChangeEoView.vue";
import ListPengajuanEoView from "@/views/ListPengajuanEoView.vue";
import ListEoApproveView from "@/views/ListEoApproveView.vue";
import ChangeLiburEoView from "@/views/ChangeLiburEoView.vue";
import ListLiburEoApproveView from "@/views/ListLiburEoApproveView.vue";
import ListPengajuanLiburEoView from "@/views/ListPengajuanLiburEoView.vue";
const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/cuti",
    name: "cuti",
    component: CutiView,
  },
  {
    path: "/sakit",
    name: "sakit",
    component: SakitView,
  },
  {
    path: "/izin",
    name: "izin",
    component: IzinView,
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/absen-masuk",
    name: "absen masuk",
    component: AbsenMasukView,
  },
  {
    path: "/absen-keluar",
    name: "absen keluar",
    component: AbsenKeluarView,
  },
  {
    path: "/list-absen",
    name: "list absen",
    component: ListAbsenView,
  },
  {
    path: "/detil-absensi",
    name: "detil absensi",
    component: DetilAbsenView,
  },
  {
    path: "/list-izin",
    name: "list izin",
    component: ListIzinView,
  },
  {
    path: "/list-izin-pending",
    name: "list izin pending",
    component: ListIzinPendingView,
  },
  {
    path: "/list-izin-setuju",
    name: "list izin setuju",
    component: ListIzinSetujuView,
  },
  {
    path: "/list-izin-tidak-setuju",
    name: "list izin tidak setuju",
    component: ListIzinTidakSetujuView,
  },
  {
    path: "/list-sakit",
    name: "list sakit",
    component: ListSakitView,
  },
  {
    path: "/list-cuti",
    name: "list cuti",
    component: ListCutiView,
  },
  {
    path: "/pdfcuti",
    name: "pdf cuti",
    component: DownloadPdfView,
  },
  {
    path: "/list-cuti-pending",
    name: "list cuti pendnig",
    component: ListCutiPendingView,
  },
  {
    path: "/list-cuti-setuju",
    name: "list cuti setuju",
    component: ListCutiSetujuView,
  },
  {
    path: "/list-cuti-tidak-setuju",
    name: "list cuti tidak setuju",
    component: ListCutiTidakSetujuView,
  },
  {
    path: "/list-progress-report",
    name: "list progress report",
    component: ListProgressReportView,
  },
  {
    path: "/detil-progress-report",
    name: "detil progress report",
    component: DetilProgressReport,
  },
  {
    path: "/list-cuti-approve",
    name: "list cuti approve",
    component: ListCutiApprove,
  },
  {
    path: "/list-ph-approve",
    name: "list ph approve",
    component: ListPhApproveView,
  },
  {
    path: "/list-libur-eo-approve",
    name: "list libur eo approve",
    component: ListLiburEoApproveView,
  },
  {
    path: "/list-ijin-approve",
    name: "list ijin approve",
    component: ListIjinApprove,
  },
  {
    path: "/list-cuti-all",
    name: "list cuti all",
    component: ListCutiAllView,
  },
  {
    path: "/list-internal-office",
    name: "list internal office",
    component: ListInternalOfficeView,
  },
  {
    path: "/list-client-site",
    name: "list client site",
    component: ListClientSiteView,
  },
  {
    path: "/list-lainnya",
    name: "list lainnya",
    component: ListLainnyaView,
  },
  {
    path: "/ubah-lokasi",
    name: "ubah lokasi",
    component: UbahLokasiView,
  },
  {
    path: "/data-absensi-pegawai",
    name: "data absensi pegawai",
    component: DataAbsensiPegawaiView,
  },
  {
    path: "/list-ubah-lokasi",
    name: "list ubah lokasi",
    component: ListUbahLokasiView,
  },
  {
    path: "/list-sakit-all",
    name: "list sakit all",
    component: ListSakitAllView,
  },
  {
    path: "/faq",
    name: "faq",
    component: FAQView,
  },
  {
    path: "/list-cuti-bersama",
    name: "list cuti bersama",
    component: ListCutiBersamaView,
  },
  {
    path: "/list-izin-all",
    name: "list izin all",
    component: ListIzinAllView,
  },
  {
    path: "/home-lite",
    name: "home lite",
    component: HomeLite,
  },
  {
    path: "/data-pic-monitoring",
    name: "data pic monitoring",
    component: DataPicMonitoringView,
  },
  {
    path: "/list-penugasan-tim",
    name: "list penugasan tim",
    component: ListPenugasanTimTeknis,
  },
  {
    path: "/change-holiday",
    name: "change holiday",
    component: ChangeHolidayView,
  },
  {
    path: "/change-eo",
    name: "change eo",
    component: ChangeEoView,
  },
  {
    path: "/change-libur-eo",
    name: "change libur eo",
    component: ChangeLiburEoView,
  },
  {
    path: "/list-ph",
    name: "list ph",
    component: ListPegawaiPhAllView,
  },
  {
    path: "/list-eo",
    name: "list eo",
    component: ListPengajuanEoView,
  },
  {
    path: "/list-libur-eo",
    name: "list libur eo",
    component: ListPengajuanLiburEoView,
  },
  {
    path: "/list-eo-approve",
    name: "list eo approve",
    component: ListEoApproveView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
