<template>
  <back-section :menu="nama" />
  <list-data-cuti-all />
</template>
<script>
import ListDataCutiAll from "@/components/ListDataCutiAll.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "Daftar Cuti Pegawai",
    };
  },
  components: { BackSection, ListDataCutiAll },
};
</script>
