<template>
  <div class="lstAprCti">
    <div class="col-12">
      <div class="list-group">
        <div
          v-for="(item, index) in listOfApprovePh"
          :key="index"
          class="card m-2 py-1 px-2"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-3">Pengajuan Extra Off</h5>
            <small class="text-muted">{{
              formatDatetime(item.created_on)
            }}</small>
          </div>
          <p class="mb-2">Diajukan Oleh : <br />{{ item.nm_pegawai }}</p>
          <p class="mb-2">Tanggal : <br />{{ formatDate(item.tanggal_ph) }}</p>
          <p class="mb-2">Keterangan : <br />{{ item.keterangan }}</p>
          <small class="text-muted" v-if="item.status == 0"
            >Menunggu di Approve</small
          >
          <small class="text-success" v-else-if="item.status == 1"
            >Disetujui</small
          >
          <small class="text-danger" v-else>Tidak Disetujui</small>
          <div class="text-end" v-show="item.status < 1">
            <button
              type="button"
              class="btn btn-primary mx-2"
              @click.prevent="approve(item.autono, item.id_employee)"
            >
              Approve
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click.prevent="decline(item.autono)"
            >
              Decline
            </button>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfApprovePh: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    loadMore(pages) {
      try {
        axios
          .get(this.UrlApi + "listlibureo?page=" + pages, { headers: headers })
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              res.data.data.forEach((element) => {
                if (element.data.length > 0) {
                  this.page += 1;
                  Array.prototype.push.apply(
                    this.listOfApprovePh,
                    element.data
                  );
                  // console.log(this.listOfApprovePh);
                } else {
                  this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                  this.btnDisabled = true;
                }
              });
              console.log();
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    formatDatetime(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    approve(autono, idpeg) {
      try {
        axios
          .post(
            this.UrlApi + "listlibureo/approve",
            {
              autono: autono,
              id_pegawai: idpeg,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              Swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              this.getApproved();
              // console.log(res.data.success);
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              // console.log(res);
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    decline(autono) {
      Swal.fire({
        title: "Masukan alasan pengajuan ditolak",
        input: "textarea",
        inputPlaceholder: "Type your message here...",
        inputAttributes: {
          "aria-label": "Type your message here",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: (kets) => {
          try {
            axios
              .post(
                this.UrlApi + "listlibureo/decline",
                {
                  autono: autono,
                  keterangan: kets,
                },
                { headers: headers }
              )
              .then((res) => {
                if (res.data.success && res.data.success !== null) {
                  Swal.fire({
                    icon: "success",
                    title: "Ditolak!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.getApproved();
                  // console.log(res.data.success);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Gagal!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  // console.log(res);
                }
              })
              .catch((err) => {
                if (err.response) {
                  console.log("error response");
                } else if (err.request) {
                  console.log("error request");
                } else {
                  console.log("error");
                }
              });
          } catch (error) {
            console.log(error);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
    getApproved() {
      try {
        axios
          .get(this.UrlApi + "listlibureo?page=", { headers: headers })
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              //   this.dataEmpty = res.data.data.length > 0 ? true : false;
              res.data.data.forEach((element) => {
                this.listOfApprovePh = element.data;
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getApproved();
  },
};
</script>
