<template>
  <back-section :menu="nama" :btn="btn" />
  <list-data-cuti />
</template>
<script>
import ListDataCuti from "@/components/ListDataCuti.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Cuti",
      btn: true,
    };
  },
  components: { ListDataCuti, BackSection },
};
</script>
