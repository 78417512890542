<template>
  <div class="cuti">
    <BackSection :menu="name" />
    <div class="container-fluid">
      <ChangeLibur />
    </div>
  </div>
</template>

<script>
import ChangeLibur from "@/components/FormChangeLiburEo.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Form Pengajuan Extra Off",
    };
  },
  components: {
    ChangeLibur,
    BackSection,
  },
};
</script>
