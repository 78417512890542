<template>
  <div class="keluar">
    <BackSection :menu="name" />
    <div class="container-fluid py-2">
      <div id="accordion" class="accordion-container">
        <div
          v-for="(item, index) in listFaq"
          :key="index"
          :class="[
            'accordion card m-2 mb-2',
            { 'is-open': isOpen.includes(index) },
          ]"
        >
          <div
            class="accordion-header card-header bg-secondary text-white"
            @click="toggleAccordion(index)"
          >
            {{ item.pertanyaan }}
          </div>
          <div class="accordion-body">
            <div class="accordion-content" v-html="item.jawaban"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import BackSection from "@/components/BackSection.vue";
import Swal from "sweetalert2";
export default {
  data() {
    return {
      name: "FAQ",
      listFaq: [],
      isOpen: [],
    };
  },
  components: {
    BackSection,
  },
  methods: {
    toggleAccordion(index) {
      // console.log(index);
      if (this.isOpen.includes(index)) {
        this.isOpen = this.isOpen.filter((i) => i !== index);
        return;
      }

      this.isOpen.push(index);
    },
  },
  created() {
    try {
      axios
        .get(this.UrlApi + "faq")
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            // this.dataEmpty = res.data.data.length > 0 ? true : false;
            this.listFaq = res.data.data[0];
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style>
.accordion:not(.is-open) .accordion-body {
  display: none;
}
</style>
