<template>
  <div>
    <form @submit.prevent="submit" class="row g-3" ref="fPh">
      <div class="col-12">
        <label>Tanggal</label>
        <input type="date" class="form-control" v-model="tanggalEo" required />
      </div>
      <!-- <div class="col-12">
        <label>Jam Mulai</label>
        <input type="time" class="form-control" required v-model="jam_mulai" />
      </div> -->
      <div class="col-12">
        <label>Jam Mulai</label>
        <input type="time" class="form-control" required v-model="jam_mulai" />
      </div>
      <div class="col-12">
        <label>Keterangan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="keterangan"
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Submit</button>
        <a @click="$router.push('/')" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data: function () {
    return {
      tanggalEo: null,
      keterangan: null,
      jam_mulai: null,
      jam_selesai: null,
      masuk: localStorage.getItem("masuk"),
    };
  },
  methods: {
    submit() {
      //   if (localStorage.getItem("masuk") !== null) {
      //   let Dt = new Date();
      //   let Hrs = Dt.getHours();
      //   Hrs = ("0" + Hrs).slice(-2);
      //   let Mnt = Dt.getMinutes();
      //   Mnt = ("0" + Mnt).slice(-2);
      //   let Tm = (Hrs + ":" + Mnt).toString();
      axios
        .post(
          this.UrlApi + "storeoff",
          {
            // id: JSON.parse(localStorage.getItem("masuk"))[0]["absen"],
            // jam_pulang: Tm,
            // kegiatan: this.keterangan,
            id_pegawai: localStorage.getItem("id"),
            tanggal: this.tanggalEo,
            ket: this.keterangan,
            jam_mulai: this.jam_mulai,
            // jam_selesai: this.jam_selesai,
          },
          { headers: headers }
        )
        .then((res) => {
          Swal.fire({
            icon: "success",
            title: "berhasil!",
            text: res.data.message,
            showConfirmButton: false,
            timer: 1500,
          });
          this.keterangan = null;
          this.tanggalEo = null;
          this.jam_mulai = null;
          //   this.jam_selesai = null;
        });
      //   } else {
      //     Swal.fire({
      //       icon: "info",
      //       title: "",
      //       text: "Silahkan Absen Masuk terlebih dahulu!",
      //       showConfirmButton: false,
      //       timer: 1500,
      //     });
      //     this.keterangan = null;
      //     this.tanggalEo = null;
      //     // this.jam_mulai = null;
      //     this.jam_selesai = null;
      //   }
    },
  },
};
</script>

<style scoped></style>
