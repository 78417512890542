<template>
  <back-section :menu="nama" />
  <list-data-cuti-setuju />
</template>
<script>
import BackSection from "@/components/BackSection.vue";
import ListDataCutiSetuju from "@/components/ListDataCutiSetuju.vue";
export default {
  setup() {
    return {
      nama: "List Cuti Disetujui",
    };
  },
  components: { BackSection, ListDataCutiSetuju },
};
</script>
