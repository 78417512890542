<template>
  <div class="form-cuti">
    <div class="overlay" v-show="loadingProg">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <h4>
      <label class="badge bg-primary mt-2"
        >Sisa Cuti Anda : {{ sisaCuti }}</label
      >
    </h4>
    <form @submit.prevent="submitcuti" class="row g-3" ref="fCuti">
      <div class="col-6">
        <label>Tanggal Ambil Cuti</label>
        <input type="date" class="form-control" v-model="awal" required />
      </div>
      <div class="col-6">
        <label>Tanggal Selesai Cuti</label>
        <input type="date" class="form-control" v-model="akhir" required />
      </div>
      <div class="col-12">
        <label>Keterangan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="keterangan"
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Ajukan</button>
        <a @click="$router.go(-1)" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data: function () {
    return {
      awal: null,
      akhir: null,
      keterangan: null,
      id: parseInt(localStorage.getItem("id")),
      firebaseToken: [localStorage.getItem("firebaseToken")],
      loadingProg: false,
      sisaCuti: "",
    };
  },
  methods: {
    async submitcuti() {
      this.loadingProg = true;
      // console.log(this.id);
      // this.socket.emit("cuti", {
      //   nama: localStorage.getItem("nama"),
      //   keterangan: this.keterangan,
      //   tanggal_awal_cuti: this.formatDate(this.awal),
      //   tanggal_akhir_cuti: this.formatDate(this.akhir),
      //   nomor: JSON.parse(localStorage.getItem("nomorpic")),
      // });
      try {
        axios
          .post(
            this.UrlApi + "pengajuan/cuti",
            {
              tanggal_awal_cuti: this.awal,
              tanggal_akhir_cuti: this.akhir,
              keterangan: this.keterangan,
              id_employee: this.id,
              location_name: localStorage.getItem("jabatan"),
              level_name: localStorage.getItem("jabatan"),
              created_by: localStorage.getItem("nama"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              const pesan =
                "*Pengajuan Cuti* :" +
                "\n" +
                "Nama : " +
                "\n" +
                localStorage.getItem("nama") +
                "\n" +
                "Tanggal Awal Cuti : " +
                "\n" +
                this.formatDate(this.awal) +
                "\n" +
                "Tanggal Akhir Cuti : " +
                "\n" +
                this.formatDate(this.akhir) +
                "\n" +
                "Keterangan : " +
                "\n" +
                this.keterangan +
                "\n" +
                "\n" +
                "_*Silahkan cek di aplikasi presensi untuk approve atau decline*_";

              let nomor = JSON.parse(localStorage.getItem("nomorpic"));
              for (var i = 0; i < nomor.length; i++) {
                let formatted = nomor[i].no_tlp.replace(/\D/g, "");

                if (formatted.startsWith("0")) {
                  formatted = "62" + formatted.substr(1);
                }

                //kriim ke pic
                try {
                  axios
                    .post(this.UrlApi + "postwa", {
                      url: "chat/sendmessage/" + formatted,
                      message: pesan,
                    })
                    .then(() => {
                      // console.log(res);
                    });
                } catch (error) {
                  console.log(error);
                }
              }

              //kirim ke group
              try {
                axios
                  .post(this.UrlApi + "postwa", {
                    url: "group/sendmessage/HRD info",
                    message: pesan,
                  })
                  .then(() => {
                    // console.log(res);
                  });
              } catch (error) {
                console.log(error);
              }

              this.loadingProg = false;
              // this.getFcmToken();
              Swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              this.$nextTick(function () {
                this.$refs.fCuti.reset();
              });
              // console.log(res.data.success);
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              this.loadingProg = false;
              // console.log(res);
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
              this.loadingProg = false;
            } else if (err.request) {
              console.log("error request");
              this.loadingProg = false;
            } else {
              console.log(err);
              this.loadingProg = false;
            }
          });
      } catch (error) {
        console.log(error);
        this.loadingProg = false;
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    // getFcmToken() {
    //   try {
    //     axios
    //       .get(this.UrlApi + "fcmtoken", { headers: headers })
    //       .then((res) => {
    //         if (res.data.result !== null) {
    //           for (let i = 0; i < res.data.result.length; i++) {
    //             var token = res.data.result[i].about;
    //             // this.sendNotif(token);
    //           }
    //         } else {
    //           console.log(res.data.result);
    //         }
    //       })
    //       .catch((err) => {
    //         if (err.response) {
    //           console.log("error response");
    //         } else if (err.request) {
    //           console.log("error request");
    //         } else {
    //           console.log("error");
    //         }
    //       });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
    // sendNotif(token) {
    //   try {
    //     axios
    //       .post(
    //         "https://fcm.googleapis.com/fcm/send",
    //         {
    //           to: token,
    //           notification: {
    //             body: localStorage.getItem("nama") + " telah mengajukan cuti",
    //             title: "Pengajuan Cuti",
    //             icon: "/vtagroup.png",
    //           },
    //         },
    //         {
    //           headers: {
    //             "Content-Type": "application/json",
    //             Authorization:
    //               "key=AAAA8MYU5dU:APA91bE4udM3VgHg4dHbYwOy-MrVEGBOvcQFtKW-0x5oPazHypLgwJbvW-Hl8QzMLhBJoaYxt4BwFDI-msNIJeB975vcfreTQ99SfJWBi2ft6dBb1eiOzUMJWP2FOdsBLOsjj3-7lX6u",
    //           },
    //         }
    //       )
    //       .then((res) => {
    //         console.log(res);
    //       });
    //   } catch (error) {
    //     console.log(error);
    //   }
    // },
  },
  created() {
    var parse = JSON.parse(localStorage.getItem("ijin"));
    this.sisaCuti = parse[0].sisacuti;
    // this.socket.on("connect", () => {
    //   console.log("connected");
    // });
  },
};
</script>
