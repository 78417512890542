<template>
  <back-section :menu="nama" />
  <list-data-sakit-all />
</template>
<script>
import BackSection from "@/components/BackSection.vue";
import ListDataSakitAll from "@/components/ListDataSakitAll.vue";
export default {
  setup() {
    return {
      nama: "Daftar Sakit Pegawai",
    };
  },
  components: { BackSection, ListDataSakitAll },
};
</script>
