<template>
  <div class="izin">
    <BackSection :menu="name" />
    <div class="container-fluid">
      <DetilAbsen />
    </div>
  </div>
</template>

<script>
import BackSection from "@/components/BackSection.vue";
import DetilAbsen from "@/components/DetilAbsensi.vue";
export default {
  setup() {
    return {
      name: "Detil",
    };
  },
  components: {
    DetilAbsen,
    BackSection,
  },
};
</script>
