<template>
  <div class="masuk">
    <BackSection :menu="name" />
    <div class="container-fluid py-2">
      <CardClientSite />
    </div>
  </div>
</template>
<script>
import CardClientSite from "@/components/CardClientSite.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "List Client Site",
    };
  },
  components: {
    BackSection,
    CardClientSite,
  },
};
</script>
