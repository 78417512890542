<template>
  <back-section :menu="nama" :btn="btn" />
  <ListDataEo />
</template>
<script>
import ListDataEo from "@/components/ListDataEo.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Pengajuan Overtime",
      btn: true,
    };
  },
  components: { ListDataEo, BackSection },
};
</script>
