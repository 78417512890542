<template>
  <div class="lstIzin">
    <div class="col-12">
      <div class="container-fluid my-3">
        <div id="accordion" class="accordion-container">
          <div
            v-for="(total, index) in listTotalEo"
            :key="total"
            :class="[
              'accordion card mb-2 border-dark',
              { 'is-open': isOpen.includes(index) },
            ]"
          >
            <div
              class="accordion-header card-header bg-secondary text-white"
              style="max-height: 200px"
              @click="toggleAccordion(index)"
            >
              {{ index + 1 }}. {{ total.nm_pegawai }} - Total
              {{ total.total_jam_eo }}
            </div>
            <div class="accordion-body p-0">
              <div
                v-for="(item, index) in listOfeo"
                :key="index"
                class="py-1 px-2 mt-2"
              >
                <div class="card">
                  <div class="card-body">
                    <p class="mb-1">Tanggal : {{ formatDate(item.tanggal) }}</p>
                    <p class="mb-1">Jam Mulai : {{ item.jam_mulai }}</p>
                    <p class="mb-1">Jam Selesai : {{ item.jam_selesai }}</p>
                    <p class="mb-1">Keterangan : {{ item.ket }}</p>
                    <p class="mb-1">
                      Total EO:
                      {{ hitungSelisih(item.jam_mulai, item.jam_selesai) }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <button
          type="button"
          class="btn btn-succes btn-sm col-12"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button> -->
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfeo: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      tglsaatini: new Date().toISOString().slice(0, 10),
      headers: "",
      listTotalEo: [],
      isOpen: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    loadTotalEo() {
      try {
        axios
          .get(this.UrlApi + "pegawai/totaleo", { headers: this.headers })
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              console.log(res.data.data);
              this.listTotalEo = res.data.data;
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    hitungSelisih(startTime, endTime) {
      let start = moment(startTime, "HH:mm:ss");
      let end = moment(endTime, "HH:mm:ss");

      // Jika waktu akhir lebih awal dari waktu mulai, tambahkan satu hari ke waktu akhir
      if (end.isBefore(start)) {
        end.add(1, "day");
      }

      // Hitung selisih waktu dalam durasi
      let duration = moment.duration(end.diff(start));

      // Ekstrak jam dan menit dari durasi
      let hours = Math.floor(duration.asHours());
      let minutes = duration.minutes();
      if (minutes > 0) {
        return `${hours} Jam ${minutes} menit`;
      } else {
        return `${hours} Jam`;
      }
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    loadMore(pages) {
      try {
        axios
          .get(this.UrlApi + "listeo/all?page=" + pages, {
            headers: this.headers,
          })
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(this.listOfeo, res.data.data);
                // console.log(this.listOfeo);
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    toggleAccordion(index) {
      // console.log(id_entitas);
      if (this.openedAccordion === index) {
        this.isOpen = this.isOpen.filter((i) => i !== index);
        this.openedAccordion = null;
        return;
      } else {
        if (this.isOpen.includes(index)) {
          this.isOpen = this.isOpen.filter((i) => i !== index);
          return;
        }
      }
      this.isOpen = this.isOpen.filter((i) => i !== this.openedAccordion);
      this.openedAccordion = index;

      this.isOpen.push(index);
      this.listOfAbsensi = [];
      try {
        axios
          .get(this.UrlApi + "listeo/all", {
            headers: this.headers,
          })
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.listOfeo = res.data.data;
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    // try {
    //   axios
    //     .get(this.UrlApi + "listeo/all?page=", { headers: this.headers })
    //     .then((res) => {
    //       if (res.data.success && res.data.success !== null) {
    //         this.dataEmpty = res.data.data.length > 0 ? true : false;
    //         this.listOfeo = res.data.data;
    //         // console.log(res.data.data);
    //       } else {
    //         Swal.fire({
    //           icon: "error",
    //           title: "Gagal!",
    //           text: res.data.message,
    //           showConfirmButton: false,
    //           timer: 1500,
    //         });
    //       }
    //     })
    //     .catch((err) => {
    //       if (err.response) {
    //         console.log("error response");
    //       } else if (err.request) {
    //         console.log("error request");
    //       } else {
    //         console.log("error");
    //       }
    //     });
    // } catch (error) {
    //   console.log(error);
    // }

    this.loadTotalEo();
  },
};
</script>
