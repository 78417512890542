<template>
  <div class="login">
    <div class="container-fluid vh-100 clogin py-5">
      <img
        src="@/assets/vtagroup.png"
        alt="login"
        class="mx-auto py3 d-block"
        style="width: 200px"
      />
      <!-- <h4 class="text-center text-white">VTA GROUP</h4> -->
      <h6 class="text-center text-white mt-2">PRESENSI</h6>
      <div class="overlay mt-5" v-if="loadingProg">
        <div class="d-flex justify-content-center">
          <div class="spinner-border" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </div>
      <form @submit.prevent="submit" class="row g-3 mx-4" v-else>
        <div class="col-12">
          <label class="text-white">Username</label>
          <input
            type="text"
            class="form-control"
            v-model="email"
            required
            placeholder="masukan username anda"
          />
        </div>
        <div class="col-12">
          <label class="text-white">Password</label>
          <input
            type="password"
            class="form-control"
            v-model="password"
            required
            placeholder="masukan password anda"
          />
        </div>
        <div class="col-12">
          <button class="btn btn-primary float-end" type="submit">Masuk</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
const axios = require("axios");
const Swal = require("sweetalert2");
// import { collection, addDoc } from "firebase/firestore";
export default {
  data() {
    return {
      email: "",
      password: "",
      headers: "",
      loadingProg: false,
    };
  },
  methods: {
    async submit() {
      this.loadingProg = true;
      try {
        axios
          .post(this.UrlApi + "login", {
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
            email: this.email,
            password: this.password,
          })
          .then((res) => {
            this.loadingProg = false;
            Swal.fire({
              icon: "success",
              title: "Berhasil!",
              text: "Anda Berhasil Login",
              showConfirmButton: false,
              timer: 1500,
            });
            localStorage.setItem("nama", res.data.data.name);
            localStorage.setItem("jabatan", res.data.data.jabatan);
            localStorage.setItem("id", res.data.data.autono);
            localStorage.setItem("pt", res.data.data.entitas);
            localStorage.setItem("total_jam", res.data.data.total_jam_eo);
            sessionStorage.setItem("key", res.data.data.token);
            if (res.data.data.jabatan == "HR MANAGEMENT") {
              this.storeFirebaseToken(res.data.data.id);
            }
            if (
              res.data.data.sts_absen === "" ||
              res.data.data.sts_absen === null
            ) {
              localStorage.removeItem("masuk");
            } else {
              var data = res.data.data.sts_absen.split(",");
              // console.log(data[1]);
              localStorage.setItem(
                "masuk",
                JSON.stringify([
                  {
                    masuk: true,
                    absen: data[1],
                    keluar: false,
                    tanggal: data[2],
                  },
                ])
              );
            }
            this.$router.push("/");
          })
          .catch((err) => {
            if (err.response) {
              this.loadingProg = false;
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: "Password atau Email Salah",
                showConfirmButton: false,
                timer: 1500,
              });
              console.log("error response");
            } else if (err.request) {
              this.loadingProg = false;
              console.log(err.request);
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: "Request Gagal periksa server atau koneksi anda",
                showConfirmButton: false,
                timer: 1500,
              });
            } else {
              this.loadingProg = false;
              console.log(err);
            }
          });
      } catch (error) {
        this.loadingProg = false;
        console.log(error);
      }
    },
    storeFirebaseToken(id) {
      try {
        axios
          .post(
            this.UrlApi + "storefirebasetoken",
            {
              id: id,
              token: localStorage.getItem("firebaseToken"),
            },
            {
              headers: this.headers,
            }
          )
          .then(() => {
            console.log("success");
          })
          .catch((err) => {
            if (err.response) {
              console.log(err.response);
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    // try {
    //   const docRef = addDoc(collection(this.db, "users"), {
    //     first: "Ada",
    //     last: "Lovelace",
    //     born: 1815,
    //   });
    //   console.log("Document written with ID: ", docRef.id);
    // } catch (e) {
    //   console.error("Error adding document: ", e);
    // }
  },
};
</script>
