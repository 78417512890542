<template>
  <div class="lstAprCti">
    <div class="col-12">
      <div class="list-group">
        <div
          v-for="(item, index) in listOfApproveIjin"
          :key="index"
          class="card m-2 py-1 px-2"
        >
          <div class="d-flex w-100 justify-content-between">
            <h5 class="mb-1">Pengajuan Ijin</h5>
            <small class="text-muted">{{
              formatDatetime(item.created_on)
            }}</small>
          </div>
          <p class="mb-1">Diajukan Oleh : {{ item.created_by }}</p>
          <p class="mb-1">Tanggal Ijin : {{ formatDate(item.tanggal_awal) }}</p>
          <p class="mb-1">Keterangan : {{ item.keterangan }}</p>
          <p class="mb-1">File :</p>
          <div class="col-3" v-if="item.file_upload == 0">-</div>
          <div class="col-3 mx-3" v-else>
            <button class="btn" @click="openImg(img + item.file_upload)">
              <img
                src="../../../public/img/home/folder.png"
                class="img-responsive d-block w60"
              />
            </button>
          </div>
          <small class="text-danger" v-if="item.status == 0"
            >Menunggu di Approve</small
          >
          <small class="text-success" v-else-if="item.status == 1"
            >Disetujui</small
          >
          <small class="text-danger" v-else>Tidak Disetujui</small>
          <div class="text-end">
            <button
              type="button"
              class="btn btn-primary mx-2"
              @click.prevent="approve(item.autono)"
            >
              Approve
            </button>
            <button
              type="button"
              class="btn btn-danger"
              @click.prevent="decline(item.autono)"
            >
              Decline
            </button>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfApproveIjin: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      img: "https://api.vitech.asia/storage/upload/izin/",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    openImg(url) {
      // console.log(url);
      window.open(url);
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listapproveizinperpic?page=" + pages,
            {
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(
                  this.listOfApproveIjin,
                  res.data.data
                );
                // console.log(this.listOfApproveIjin);
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    formatDatetime(date) {
      return moment(date).format("hh:mm - DD-MM-YYYY");
    },
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    approve(autono) {
      try {
        axios
          .post(
            this.UrlApi + "pengajuan/izin/approve",
            {
              id: autono,
              jabatan: localStorage.getItem("jabatan"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              Swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              this.getApproved();
              // console.log(res.data.success);
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              // console.log(res);
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    decline(autono) {
      try {
        axios
          .post(
            this.UrlApi + "pengajuan/izin/decline",
            {
              id: autono,
              jabatan: localStorage.getItem("jabatan"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              Swal.fire({
                icon: "success",
                title: "Ijin Ditolak!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              this.getApproved();
              // console.log(res.data.success);
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
              // console.log(res);
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getApproved() {
      try {
        axios
          .post(
            this.UrlApi + "listapproveizinperpic?page=",
            {
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.dataEmpty = res.data.data.length > 0 ? true : false;
              this.listOfApproveIjin = res.data.data;
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.getApproved();
  },
};
</script>
