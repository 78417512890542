<template>
  <div class="form-sakit">
    <div class="overlay" v-show="loadingProg">
      <div class="d-flex justify-content-center">
        <div class="spinner-border" role="status">
          <span class="visually-hidden">Loading...</span>
        </div>
      </div>
    </div>
    <form @submit.prevent="submit" class="row g-3" ref="fUbahLokasi">
      <div class="col-12">
        <label>Jam</label>
        <input
          type="time"
          class="form-control"
          required
          :value="jamMasuk"
          @change="onChangeJamMasuk($event.target.value)"
        />
      </div>
      <div class="col-12">
        <label>Kantor / Diluar Kantor :</label>
        <select
          class="form-select"
          v-model="selectedSite"
          @change="onChange($event)"
          required
        >
          <option disabled value="0">Pilih</option>
          <option value="1">Kantor</option>
          <option value="2">Diluar Kantor</option>
        </select>
      </div>
      <div class="col-12" v-show="selectedSite == 1">
        <label>Pilih Site :</label>
        <select
          class="form-select"
          v-model="selectedOffice"
          @change="changeLoc($event)"
        >
          <option value="" disabled>Pilih Site</option>
          <option
            v-for="val in select"
            :key="val.autono"
            :value="val.nama_perusahaan"
            :lonlat="val.lonLat"
            required
          >
            {{ val.nama_perusahaan }}
          </option>
        </select>
      </div>
      <div class="col-12" v-show="selectedSite == 2">
        <label>Pilih Site :</label>
        <select
          class="form-select"
          v-model="selectedOffice"
          @change="onChangeSite($event)"
        >
          <option value="" disabled>Pilih Site</option>
          <option
            v-for="val in select"
            :key="val.autono"
            :value="val.nama_site"
            required
          >
            {{ val.nama_site }}
          </option>
          <option value="00">Lainnya</option>
        </select>
      </div>
      <div class="col-12" v-show="selectedOffice === '00'">
        <label>Isi Nama Site</label>
        <input
          class="form-control"
          type="text"
          v-model="siteLainya"
          placeholder="Isi Nama Site"
        />
      </div>
      <div class="col-12">
        <label>Keterangan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="keterangan"
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Ajukan</button>
        <a @click="$router.go(-1)" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "multipart/form-data",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
var minRad = 500;
export default {
  data: function () {
    return {
      keterangan: null,
      id: parseInt(localStorage.getItem("id")),
      loadingProg: false,
      select: [],
      selectedSite: "",
      selectedOffice: "",
      jamMasuk: "",
      siteLainya: null,
      absenLoc: null,
    };
  },
  methods: {
    checkRadius(dat, urls) {
      console.log(urls);
      var self = this;
      let pecahdata = dat.split(",");
      let lat = localStorage.getItem("lat");
      let lon = localStorage.getItem("lon");

      var R = 3958.8; // Radius bumi dalam mils
      var rlat1 = lat * (Math.PI / 180); // Convert degrees to radians
      var rlat2 = parseFloat(pecahdata[1]) * (Math.PI / 180); // Convert degrees to radians
      var difflat = rlat2 - rlat1; // Radian difference (latitudes)
      var difflon = (parseFloat(pecahdata[0]) - lon) * (Math.PI / 180); // Radian difference (longitudes)

      var d =
        2 *
        R *
        Math.asin(
          Math.sqrt(
            Math.sin(difflat / 2) * Math.sin(difflat / 2) +
              Math.cos(rlat1) *
                Math.cos(rlat2) *
                Math.sin(difflon / 2) *
                Math.sin(difflon / 2)
          )
        );

      let miles = d;
      let kilometers = miles * 1.609344;
      let meters = kilometers * 1000;
      if (parseFloat(meters.toFixed(3)) < minRad) {
        var localstorage = JSON.parse(localStorage.getItem("masuk"));
        try {
          axios
            .post(
              this.UrlApi + "ubahlokasi",
              {
                id_pegawai: localStorage.getItem("id"),
                id_absen_harian: localstorage[0].absen,
                tanggal_absensi: localstorage[0].tanggal,
                jam: this.jamMasuk,
                site:
                  this.selectedOffice == "00"
                    ? this.siteLainya
                    : this.selectedOffice,
                lokasi: localStorage.getItem("address"),
                keterangan: this.keterangan,
              },
              { headers: headers }
            )
            .then((res) => {
              // console.log(res.data.data);
              if (res.data.success && res.data.success !== null) {
                // console.log(res.data.message);
                this.loadingProg = false;
                Swal.fire({
                  icon: "success",
                  title: "Berhasil!",
                  text: "Ubah Lokasi berhasil",
                  showConfirmButton: false,
                  timer: 1500,
                });
                // window.location.reload();
                this.selectedSite = 0;
                this.keterangan = "";
                this.selectedOffice = null;
                this.siteLainya = "";
              } else {
                this.loadingProg = false;
                Swal.fire({
                  icon: "error",
                  title: "Gagal!",
                  text: res.data.message,
                  showConfirmButton: false,
                  timer: 1500,
                });
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response ");
                this.loadingProg = false;
              } else if (err.request) {
                console.log("error request");
                this.loadingProg = false;
              } else {
                console.log("error");
                this.loadingProg = false;
              }
            });
        } catch (error) {
          console.log(error);
          this.loadingProg = false;
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops!!",
          text: "Maaf Lokasi Anda Masih Jauh dari Kantor!!",
          showConfirmButton: false,
          timer: 2000,
        });
        self.loadingProg = false;
      }
    },
    async submit() {
      let urls = this.UrlApi;
      this.loadingProg = true;
      var localstorage = JSON.parse(localStorage.getItem("masuk"));
      // console.log(localstorage);
      if (localstorage !== null) {
        if (this.absenLoc !== null) {
          this.checkRadius(this.absenLoc, urls);
        } else {
          try {
            axios
              .post(
                this.UrlApi + "ubahlokasi",
                {
                  id_pegawai: localStorage.getItem("id"),
                  id_absen_harian: localstorage[0].absen,
                  tanggal_absensi: localstorage[0].tanggal,
                  jam: this.jamMasuk,
                  site:
                    this.selectedOffice == "00"
                      ? this.siteLainya
                      : this.selectedOffice,
                  lokasi: localStorage.getItem("address"),
                  keterangan: this.keterangan,
                },
                { headers: headers }
              )
              .then((res) => {
                // console.log(res.data.data);
                if (res.data.success && res.data.success !== null) {
                  // console.log(res.data.message);
                  this.loadingProg = false;
                  Swal.fire({
                    icon: "success",
                    title: "Berhasil!",
                    text: "Ubah Lokasi berhasil",
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  // window.location.reload();
                  this.selectedSite = 0;
                  this.keterangan = "";
                  this.selectedOffice = null;
                  this.siteLainya = "";
                } else {
                  this.loadingProg = false;
                  Swal.fire({
                    icon: "error",
                    title: "Gagal!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              })
              .catch((err) => {
                if (err.response) {
                  console.log("error response ");
                  this.loadingProg = false;
                } else if (err.request) {
                  console.log("error request");
                  this.loadingProg = false;
                } else {
                  console.log("error");
                  this.loadingProg = false;
                }
              });
          } catch (error) {
            console.log(error);
            this.loadingProg = false;
          }
        }
      } else {
        this.loadingProg = false;
        this.keterangan = "";
        Swal.fire({
          icon: "warning",
          title: "Gagal!",
          text: "Anda harus absen masuk terlebih dahulu",
          showConfirmButton: false,
          timer: 3000,
        });
      }
    },
    changeLoc(event) {
      // let absenLoc = event.target.getAttribute("data-lonlat");
      var options = event.target.options;
      if (options.selectedIndex > -1) {
        var name = options[options.selectedIndex].getAttribute("lonlat");
        this.absenLoc = name;
      }
    },
    onChange(event) {
      this.selectedOffice = null;
      this.selectedSite = event.target.value;
      if (event.target.value == 1) {
        axios
          .get(this.UrlApi + "getlocentitas", { headers: headers })
          .then((res) => {
            this.select = res.data.data;
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } else {
        axios
          .get(this.UrlApi + "getclientsite", { headers: headers })
          .then((res) => {
            this.select = res.data.data;
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      }
    },
    onChangeSite(event) {
      this.selectedOffice = event.target.value;
      // console.log(this.selectedOffice);
    },
    onChangeJamMasuk(event) {
      this.jamMasuk = event;
    },
  },
  created() {
    var time = new Date().toTimeString().split(" ")[0];
    var splitTime = time.split(":");
    var newTime = splitTime[0] + ":" + splitTime[1];
    this.jamMasuk = newTime;
  },
};
</script>
