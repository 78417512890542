<!-- eslint-disable vue/require-v-for-key -->
<template>
  <div class="lsitMasuk">
    <div class="row align-items-center sticky-top bg-white py-2">
      <div class="col-5 col-sm-5 col-md-5 col-lg-5 px-1">
        <select class="form-control" v-model="sel_tahun" required>
          <option v-for="year in years" :value="year" :key="year">
            {{ year }}
          </option>
        </select>
      </div>
      <div class="col-5 col-sm-5 col-md-5 col-lg-5 px-1">
        <select
          class="form-control"
          v-model="sel_bulan"
          aria-placeholder="sss"
          required
        >
          <option v-for="bln in bulan" :value="bln.value" :key="bln.value">
            {{ bln.name }}
          </option>
        </select>
      </div>
      <div class="col-2 col-sm-2 col-md-2 col-lg-2 px-1">
        <button class="btn btn-success" @click.prevent="submitCari()">
          Pilih
        </button>
      </div>
    </div>
    <div class="clearfix"></div>
    <div
      class="container-fluid my-3"
      :style="{ height: windowHeight - 160 + 'px', overflow: 'auto' }"
      @scroll="handleScroll"
    >
      <div class="row g-0 d-flex justify-content-between">
        <time
          v-for="(row, index) in listOfAbsensi"
          :key="index"
          :datetime="row.tanggal_absensi"
          class="icon col-auto my-1 mx-1"
        >
          <button
            type="button"
            class="btn btn-link text-decoration-none text-dark w-100 p-0 d-block m-0"
            @click.prevent="onButtonClik($event)"
          >
            <em :data-absensi="row.autono">{{ row.hari }}</em>
            <strong :data-absensi="row.autono">{{ row.bulan }}</strong>
            <span :data-absensi="row.autono">{{ row.tgl }}</span>
          </button>
        </time>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};
const month = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];
const idx = new Date().getMonth();
export default {
  data: function () {
    return {
      windowHeight: window.innerHeight,
      sel_tahun: new Date().getFullYear(),
      sel_bulan: month[idx],
      listOfAbsensi: [],
      id: parseInt(localStorage.getItem("id")),
      page: 1,
      detilId: 0,
      bulan: [
        {
          name: "Jan",
          value: "01",
        },
        {
          name: "Feb",
          value: "02",
        },
        {
          name: "Mar",
          value: "03",
        },
        {
          name: "Apr",
          value: "04",
        },
        {
          name: "Mei",
          value: "05",
        },
        {
          name: "Jun",
          value: "06",
        },
        {
          name: "Jul",
          value: "07",
        },
        {
          name: "Ags",
          value: "08",
        },
        {
          name: "Sep",
          value: "09",
        },
        {
          name: "Okt",
          value: "10",
        },
        {
          name: "Nov",
          value: "11",
        },
        {
          name: "Des",
          value: "12",
        },
      ],
    };
  },
  computed: {
    years() {
      const year = new Date().getFullYear();
      return Array.from(
        { length: year - 2020 },
        (value, index) => 2021 + index
      );
    },
  },
  created() {
    try {
      axios
        .post(
          this.UrlApi + "listabsensi?page=" + this.page,
          {
            id_peg: this.id,
            bulan: this.sel_bulan,
            tahun: this.sel_tahun.toString(),
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.listOfAbsensi = res.data.data;
            // console.log(this.listOfAbsensi);
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onResize);
  },
  methods: {
    onButtonClik(event) {
      window.location.href =
        "/detil-absensi?q=" + event.target.getAttribute("data-absensi");
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    handleScroll(e) {
      const clientHeight = e.target.clientHeight;
      const scrollHeight = e.target.scrollHeight;
      const scrollTop = e.target.scrollTop;
      // console.log(e.target.scrollTop.toFixed(0));
      if (scrollTop + clientHeight >= scrollHeight) {
        // console.log("Yay!");
        // this.bottom = true;
        Swal.fire({
          position: "bottom",
          text: "Semua Data Berhasi Dikeluarkan",
          showClass: {
            popup: `
      animate__animated
      animate__fadeInUp
      animate__faster
    `,
          },
          hideClass: {
            popup: `
      animate__animated
      animate__fadeOutDown
      animate__faster
    `,
          },
          grow: "row",
          showConfirmButton: false,
          timer: 1500,
        });
      } else {
        // this.bottom = false;
      }
    },
    async submitCari() {
      try {
        axios
          .post(
            this.UrlApi + "listabsensi?page=" + this.page,
            {
              id_peg: this.id,
              bulan: this.sel_bulan,
              tahun: this.sel_tahun,
            },
            { headers: headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // Swal.fire({
              //   icon: "success",
              //   title: "Berhasil!",
              //   text: res.data.message,
              //   showConfirmButton: false,
              //   timer: 1500,
              // });
              this.listOfAbsensi = res.data.data;
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
