<template>
  <div class="izin">
    <BackSection :menu="name" />
    <div class="container-fluid">
      <DetailProgressReport />
    </div>
  </div>
</template>

<script>
import BackSection from "@/components/BackSection.vue";
import DetailProgressReport from "@/components/DetailProgressReport.vue";
export default {
  setup() {
    return {
      name: "Detil",
    };
  },
  components: {
    DetailProgressReport,
    BackSection,
  },
};
</script>
