<template>
  <back-section :menu="nama" />
  <list-data-izin-tidak-setuju />
</template>
<script>
import ListDataIzinTidakSetuju from "@/components/ListDataIzinTidakSetuju.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Izin Ditolak",
    };
  },
  components: { ListDataIzinTidakSetuju, BackSection },
};
</script>
