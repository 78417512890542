<template>
  <back-section :menu="nama" />
  <list-data-ubah-lokasi />
</template>
<script>
import BackSection from "@/components/BackSection.vue";
import ListDataUbahLokasi from "@/components/ListDataUbahLokasi.vue";
export default {
  setup() {
    return {
      nama: "List Ubah Lokasi",
    };
  },
  components: { BackSection, ListDataUbahLokasi },
};
</script>
