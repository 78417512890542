<template>
  <div ref="swiper" class="swiper mb-3 mt-3">
    <span class="badge d-flex justify-content-center">
      <h6><strong>Informasi</strong></h6>
    </span>
    <div class="swiper-wrapper" v-show="listInformasi.length !== 0">
      <!-- Slides -->
      <div
        class="swiper-slide p-3 mb-3"
        v-for="data in listInformasi"
        :key="data"
      >
        <div class="card">
          <div class="card-body">
            {{ data.informasi }}
          </div>
        </div>
      </div>
    </div>
    <div class="swiper-wrapper" v-show="listInformasi.length === 0">
      <div class="swiper-slide p-3">Belum ada informasi terbaru</div>
    </div>
    <div class="swiper-pagination"></div>
  </div>
</template>
<script>
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import axios from "axios";
export default {
  data() {
    return {
      listInformasi: [],
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("key"),
      },
    };
  },
  mounted() {
    const SECOND = 1000; // milliseconds

    // init Swiper:
    new Swiper(this.$refs.swiper, {
      // configure Swiper to use modules
      modules: [Navigation, Pagination, Autoplay],
      loop: false,
      slidesPerView: 1,
      autoplay: {
        delay: 3 * SECOND,
        disableOnInteraction: false,
      },
      speed: 2 * SECOND,
      pagination: {
        el: ".swiper-pagination",
        clickable: true,
      },
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },

      // Events
      on: {
        slideChange: (swiper) => {
          this.activeIndex = swiper.realIndex;
        },
      },
    });
  },
  methods: {
    onSwiper() {
      // console.log(swiper);
    },
    onSlideChange() {
      // console.log("Change Slide");
    },
  },
  created() {
    try {
      axios
        .get(this.UrlApi + "informasi", { headers: this.headers })
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            // this.dataEmpty = res.data.data.length > 0 ? true : false;
            this.listInformasi = res.data.data[0];
          } else {
            // console.log(res);
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
<style scoped>
.swiper {
  max-width: 600px;
  background: #e8f6ef;
  border-radius: 15px;
}

.swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card {
  box-shadow: 5px 5px 7px gray;
}

.badge {
  background: #d61355;
}

.swiper-container {
  position: relative;
  width: 70%;
  margin: 0 auto;
}
.swiper-container .swiper-button-prev {
  left: -40px;
}
.swiper-container .swiper-button-next {
  right: -40px;
}
.swiper-container .swiper-pagination {
  bottom: -30px !important;
}
</style>
