<template>
  <div class="sakit">
    <BackSection :menu="name" />
    <div class="container-fluid">
      <FormUbahLokasi />
    </div>
  </div>
</template>

<script>
import FormUbahLokasi from "@/components/FormUbahLokasi.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Form Ubah Lokasi",
    };
  },
  components: {
    BackSection,
    FormUbahLokasi,
  },
};
</script>
