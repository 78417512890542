<template>
  <div class="masuk">
    <BackSection :menu="name" />
    <div class="container-fluid py-2">
      <CardLainnya />
    </div>
  </div>
</template>
<script>
import CardLainnya from "@/components/CardLainnya.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      name: "Lainnya",
    };
  },
  components: {
    BackSection,
    CardLainnya,
  },
};
</script>
