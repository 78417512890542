<template>
  <div class="lstIzin">
    <div class="col-12">
      <div class="list-group">
        <div v-for="(item, index) in listOfizin" :key="index" class="py-1 px-2">
          <a
            :href="`/pdfcuti?nama=${item.nm_pegawai}&awal=${item.tanggal_awal_cuti}&akhir=${item.tanggal_akhir_cuti}&masuk=${item.tanggal_masuk}&ket=${item.keterangan}&kontak=${kontak}&now=${tglsaatini}&entitas=${item.id_entitas}`"
            class="list-group-item list-group-item-action"
          >
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Pengajuan Cuti</h5>
              <!-- <small class="text-muted">{{
                formatDate(item.created_on)
              }}</small> -->
            </div>
            <p class="mb-1">Diajukan Oleh : {{ item.nm_pegawai }}</p>
            <p class="mb-1">Diapprove Oleh : {{ item.approved_by ?? "-" }}</p>
            <p class="mb-1">Dari : {{ formatDate(item.tanggal_awal_cuti) }}</p>
            <p class="mb-1">
              Sampai : {{ formatDate(item.tanggal_akhir_cuti) }}
            </p>
            <p class="mb-1">Lama Cuti : {{ item.total_cuti_diambil }} hari</p>
            <p class="mb-1">Keterangan : {{ item.keterangan }}</p>
            <small class="text-muted" v-if="item.status == 0">Pending</small>
            <small class="text-success" v-else-if="item.status == 1"
              >Disetujui</small
            >
            <small class="text-warning" v-else-if="item.status == 3">
              Dibatalkan
            </small>
            <small class="text-danger" v-else>Tidak Disetujui</small>
            <span
              v-show="
                item.status !== 3 && item.tanggal_awal_cuti !== tglsaatini
              "
              class="badge bg-danger mx-3"
              @click.prevent="
                batalkan(item.autono, item.id_employee, item.total_cuti_diambil)
              "
            >
              Batalkan cuti
            </span>
          </a>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-none': btnDisabled }"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfizin: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      kontak: "xxxxxxx",
      tglsaatini: new Date().toISOString().slice(0, 10),
      headers: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listcuti/pic?page=" + pages,
            {
              id: localStorage.getItem("id"),
              jabatan: localStorage.getItem("jabatan"),
            },
            {
              headers: this.headers,
            }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(this.listOfizin, res.data.data);
                // console.log(this.listOfizin);
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    getKontak() {
      try {
        axios
          .post(
            this.UrlApi + "pegawai/show",
            {
              id: this.id,
            },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              this.kontak = res.data.data[0].no_tlp;
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
    batalkan(autono, id_peg, total_cuti) {
      // console.log(autono, id_peg, total_cuti);
      Swal.fire({
        title: "Keterangan cuti dibatalkan ?",
        input: "textarea",
        inputPlaceholder: "Masukkan keterangan...",
        inputAttributes: {
          "aria-label": "Masukkan keterangan",
        },
        showCancelButton: true,
        confirmButtonText: "Submit",
        showLoaderOnConfirm: true,
        preConfirm: (login) => {
          try {
            axios
              .post(
                this.UrlApi + "pengajuan/cuti/cancel",
                {
                  id: autono,
                  id_employee: id_peg,
                  total_cuti: total_cuti,
                  keterangan: login,
                  jabatan: localStorage.getItem("jabatan"),
                },
                { headers: this.headers }
              )
              .then((res) => {
                if (res.data.success && res.data.success !== null) {
                  Swal.fire({
                    icon: "success",
                    title: "Cuti Dibatalkan!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  window.location.reload();
                  // console.log(res.data.success);
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Gagal!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  // console.log(res);
                }
              })
              .catch((err) => {
                if (err.response) {
                  console.log("error response");
                } else if (err.request) {
                  console.log("error request");
                } else {
                  console.log("error");
                }
              });
          } catch (error) {
            console.log(error);
          }
        },
        allowOutsideClick: () => !Swal.isLoading(),
      });
    },
  },
  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    try {
      axios
        .post(
          this.UrlApi + "listcuti/pic?page=",
          {
            id: localStorage.getItem("id"),
            jabatan: localStorage.getItem("jabatan"),
          },
          { headers: this.headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.dataEmpty = res.data.data.length > 0 ? true : false;
            this.listOfizin = res.data.data;
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }

    this.getKontak();
  },
};
</script>
