<template>
  <div class="lstIzin">
    <div class="col-12">
      <div class="list-group">
        <div v-for="(item, index) in listOfph" :key="index" class="py-1 px-2">
          <div class="list-group-item list-group-item-action">
            <div class="d-flex w-100 justify-content-between">
              <h5 class="mb-1">Pengajuan Extra Off</h5>
              <!-- <small class="text-muted">{{
                    formatDate(item.created_on)
                  }}</small> -->
            </div>
            <p class="mb-1">Tanggal : {{ formatDate(item.tanggal_ph) }}</p>
            <p class="mb-1">Keterangan : {{ item.keterangan }}</p>
            <small class="text-muted" v-if="item.status == 0">Pending</small>
            <small
              class="text-success"
              style="font-weight: bold"
              v-else-if="item.status == 1"
              >Disetujui</small
            >
            <small class="text-danger" style="font-weight: bold" v-else
              >Tidak Disetujui</small
            >
            <br />
            <small v-show="item.status == 2" style="font-style: italic">
              Alasan : {{ item.status_keterangan }}
            </small>
          </div>
        </div>
        <button
          type="button"
          class="btn btn-succes btn-sm"
          :class="{ 'd-block': btnDisabled }"
          :disabled="btnDisabled"
          @click.prevent="loadMore(page)"
        >
          {{ btnLoadMoreName }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
export default {
  data() {
    return {
      page: 2,
      id: localStorage.getItem("id"),
      listOfph: [],
      dataEmpty: true,
      btnDisabled: false,
      btnLoadMoreName: "Muat Lebih Banyak",
      nama: localStorage.getItem("nama"),
      kontak: "xxxxxxx",
      tglsaatini: new Date().toISOString().slice(0, 10),
      headers: "",
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    loadMore(pages) {
      try {
        axios
          .post(
            this.UrlApi + "listlibureo/all?page=" + pages,
            {
              id: localStorage.getItem("id"),
            },
            {
              headers: this.headers,
            }
          )
          .then((res) => {
            if (res.data.success && res.data.success !== null) {
              // this.dataEmpty = res.data.data.length > 0 ? true : false;
              if (res.data.data.length > 0) {
                this.page += 1;
                Array.prototype.push.apply(this.listOfph, res.data.data);
              } else {
                this.btnLoadMoreName = "Data Sudah Diload Seluruhnya";
                this.btnDisabled = true;
              }
            } else {
              Swal.fire({
                icon: "error",
                title: "Gagal!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });
            }
          })
          .catch((err) => {
            if (err.response) {
              console.log("error response");
            } else if (err.request) {
              console.log("error request");
            } else {
              console.log("error");
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
  created() {
    this.headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("key"),
    };

    try {
      axios
        .post(
          this.UrlApi + "listlibureo/all?page=",
          {
            id: localStorage.getItem("id"),
          },
          { headers: this.headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.dataEmpty = res.data.data.length > 0 ? true : false;
            this.listOfph = res.data.data;
            // console.log(res.data.data);
          } else {
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
