<template>
  <div>
    <form @submit.prevent="submitcuti" class="row g-3" ref="fPh">
      <div class="col-12">
        <label>Tanggal</label>
        <input type="date" class="form-control" v-model="tanggalPH" required />
      </div>
      <div class="col-12">
        <label>Keterangan</label>
        <textarea
          class="form-control"
          cols="30"
          rows="10"
          v-model="keterangan"
          required
        ></textarea>
      </div>
      <div class="col-12">
        <button class="btn btn-primary float-end" type="submit">Ajukan</button>
        <a @click="$router.go(-1)" class="btn btn-warning float-end mx-1"
          >Kembali</a
        >
      </div>
    </form>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
// const Swal = require("sweetalert2");
export default {
  data: function () {
    return {
      idpeg: null,
      listLibur: [],
      selectedIdLibur: "00",
      keterangan: null,
      tanggalPH: null,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("key"),
      },
      tanggalLibur: null,
    };
  },
  mounted() {
    this.idpeg = localStorage.getItem("id");
  },
  methods: {
    submitcuti() {
      try {
        axios
          .post(
            this.UrlApi + "storelibur",
            {
              id_pegawai: this.idpeg,
              tanggal: this.tanggalPH,
              keterangan: this.keterangan,
            },
            { headers: this.headers }
          )
          .then((res) => {
            if (res.data.message != "gagal") {
              Swal.fire({
                icon: "success",
                title: "Berhasil!",
                text: res.data.message,
                showConfirmButton: false,
                timer: 1500,
              });

              const pesan =
                "*Pengajuan EO* :" +
                "\n" +
                "*Nama* : " +
                "\n" +
                localStorage.getItem("nama") +
                "\n" +
                "*Tanggal* : " +
                "\n" +
                this.tanggalPH +
                "\n" +
                "*Keterangan* : " +
                "\n" +
                this.keterangan +
                "\n" +
                "\n" +
                "_*Silahkan cek di aplikasi presensi untuk approve atau decline*_";

              //kirim ke group
              try {
                axios
                  .post(this.UrlApi + "postwa", {
                    url: "group/sendmessage/HRD info",
                    message: pesan,
                  })
                  .then(() => {
                    // console.log(res);
                    this.keterangan = null;
                    this.tanggalPH = null;
                  });
              } catch (error) {
                console.log(error);
              }
            } else {
              this.keterangan = null;
              this.tanggalPH = null;
            }
          });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
