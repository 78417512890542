<template>
  <back-section :menu="nama" :btn="btn" />
  <ListDataPh />
</template>
<script>
import ListDataPh from "@/components/ListDataPh.vue";
import BackSection from "@/components/BackSection.vue";
export default {
  setup() {
    return {
      nama: "List Pengajuan PH",
      btn: true,
    };
  },
  components: { ListDataPh, BackSection },
};
</script>
