<template>
  <div class="dtlAbsen">
    <button
      class="btn btn-warning btn-sm float-end my-3"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#timeabsenpulang"
      @click.prevent="showModalsBs()"
    >
      Ubah Data
    </button>
    <br />
    <dl class="row g-2 py-3" v-for="(itm, idx) in dataView" :key="idx">
      <dt>Kantor :</dt>
      <dd>{{ itm.note ?? itm.id_entitas }}</dd>
      <dt>Tanggal :</dt>
      <dd>{{ formatDate(itm.tanggal_absensi) }}</dd>
      <dt>Jam Masuk :</dt>
      <dd>{{ itm.jam_masuk }}</dd>
      <dt>Jam Pulang :</dt>
      <dd>{{ itm.jam_pulang }}</dd>
      <dt>Lokasi Absen :</dt>
      <dd>{{ itm.keterangan }}</dd>
      <dt>Kegiatan :</dt>
      <dd>{{ itm.kegiatan }}</dd>
      <dt>Riwayat Ubah Lokasi :</dt>
      <dl v-for="(val, idx) in dataRiwayat" :key="idx" class="row px-3">
        <div class="card m-2">
          <div class="card-body text-muted">
            <div class="card-title">Pindah ke site {{ val.site }}</div>
            <div class="card-subtitle">Pada jam : {{ val.jam }}</div>
            <p class="card-text">Keterangan : {{ val.keterangan }}</p>
          </div>
        </div>
      </dl>
      <div
        class="modal fade"
        id="timeabsenpulang"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="timeabsenpulangLabel"
        aria-hidden="true"
        :class="{ 'show d-block mt-0 px-0': showModals, '': !showModals }"
        v-bind:style="showModals ? 'background-color: #0000009c' : ''"
        v-bind:role="showModals ? 'dialog' : ''"
      >
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="timeabsenpulangLabel">Ubah Data ?</h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                @click.prevent="hideModalsBs()"
              ></button>
            </div>
            <div class="modal-body">
              <form @submit.prevent="updateData(itm.autono)" ref="fEditable">
                <dl
                  class="row g-2 py-3"
                  v-for="(itm, idx) in dataView"
                  :key="idx"
                >
                  <dt>Kantor :</dt>
                  <!-- <dd>{{ itm.id_entitas }}</dd> -->
                  <select
                    class="form-control"
                    v-model="selectedSite"
                    @change="changeSite"
                  >
                    <option disabled>Pilih Site</option>
                    <option
                      v-for="dok in listSite"
                      :key="dok.autono"
                      :value="dok.nama_perusahaan"
                      :entitas="dok.autono"
                    >
                      {{ dok.nama_perusahaan }}
                    </option>
                    <option value="0" :entitas="0">Lainnya</option>
                  </select>
                  <div v-show="selectedSite === 0 || selectedSite === '0'">
                    <dt>Isi Nama Site</dt>
                    <dd>
                      <input
                        class="form-control"
                        type="text"
                        v-model="siteLainya"
                        placeholder="Isi Nama Site"
                      />
                    </dd>
                  </div>
                  <dt>Tanggal :</dt>
                  <dd>{{ formatDate(itm.tanggal_absensi) }}</dd>
                  <dt>Jam Masuk :</dt>
                  <dd>{{ itm.jam_masuk }}</dd>
                  <dt>Jam Pulang :</dt>
                  <dd>
                    <input
                      type="time"
                      class="form-control"
                      :value="itm.jam_pulang"
                      ref="jamPulangRef"
                      required
                    />
                  </dd>
                  <dt>Lokasi Absen :</dt>
                  <dd>{{ itm.keterangan }}</dd>
                  <dt>Kegiatan :</dt>
                  <dd>
                    <textarea
                      class="form-control"
                      :value="itm.kegiatan"
                      ref="kegiatanRef"
                      required
                    ></textarea>
                  </dd>
                </dl>
                <button type="submit" class="btn btn-primary mt-1 btn-block">
                  Simpan
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </dl>
  </div>
</template>
<script>
const axios = require("axios");
const Swal = require("sweetalert2");
import moment from "moment";
const headers = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + sessionStorage.getItem("key"),
};

export default {
  data() {
    return {
      uppercase: this.$route.query.q,
      dataView: [],
      empty: true,
      editKegiatan: this.$el,
      editJamPulang: this.$refs.jamPulangRef,
      dataRiwayat: [],
      listSite: {},
      selectedSite: null,
      siteLainya: null,
      id_entitas: null,
    };
  },
  methods: {
    formatDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    showModalsBs() {
      this.showModals = true;
    },
    hideModalsBs() {
      this.showModals = false;
    },
    changeSite() {
      var options = event.target.options;
      if (options.selectedIndex > -1) {
        var name = options[options.selectedIndex].getAttribute("entitas");
        this.id_entitas = name;
      }
    },
    updateData(absenid) {
      // console.log(absenid);
      if (this.$refs.kegiatanRef[0]["value"].trim() !== "") {
        // const pesan =
        //   "*Absensi Harian* :" +
        //   "\n" +
        //   "Nama : " +
        //   localStorage.getItem("nama") +
        //   "\n" +
        //   "Tanggal Absen : " +
        //   "\n" +
        //   "Site : " +
        //   "\n" +
        //   "Jam Masuk : 08:00:00" +
        //   "\n" +
        //   "Jam Pulang : " +
        //   this.$refs.jamPulangRef[0]["value"] +
        //   "\n" +
        //   "Kegiatan : ";

        // try {
        //   axios
        //     .post(this.UrlApi + "testwa", {
        //       url: "group/sendmessage/HRD info",
        //       message: pesan,
        //     })
        //     .then((res) => {
        //       console.log(res);
        //     });
        // } catch (error) {
        //   console.log(error);
        // }

        try {
          axios
            .post(
              this.UrlApi + "listabsensi/perbarui",
              {
                autono: absenid,
                id_peg: localStorage.getItem("id"),
                jam_pulang: this.$refs.jamPulangRef[0]["value"],
                kegiatan: this.$refs.kegiatanRef[0]["value"],
                nama: localStorage.getItem("nama"),
                note:
                  this.selectedSite == "0"
                    ? this.siteLainya
                    : this.selectedSite,
                id_entitas: this.id_entitas,
              },
              { headers: headers }
            )
            .then((res) => {
              const masuk = localStorage.getItem("masuk");
              if (masuk !== null) {
                if (res.data.success && res.data.success !== null) {
                  let today = new Date();
                  const offset = today.getTimezoneOffset();
                  today = new Date(today.getTime() - offset * 60 * 1000);
                  // const masuk = localStorage.getItem("masuk");
                  const tgl_masuk = JSON.parse(masuk);
                  var tgls = "";
                  const tgl = today.toISOString().split("T")[0];
                  Object.keys(tgl_masuk).forEach((key) => {
                    tgls = tgl_masuk[key].tanggal;
                  });
                  if (tgl === tgls) {
                    // console.log("updated");
                    this.$router.push("/");
                  } else {
                    // console.log("remove");
                    const pesan =
                      "*Absensi Harian* :" +
                      "\n" +
                      "Nama : " +
                      localStorage.getItem("nama") +
                      "\n" +
                      "Tanggal Absen : " +
                      this.formatDate(
                        JSON.parse(localStorage.getItem("masuk"))[0]["tanggal"]
                      ) +
                      "\n" +
                      "Site : " +
                      res.data.data[0].note +
                      "\n" +
                      "Jam Masuk : " +
                      res.data.data[0].jam_masuk +
                      "\n" +
                      "Jam Pulang : " +
                      res.data.data[0].jam_pulang +
                      "\n" +
                      "Kegiatan : " +
                      res.data.data[0].kegiatan;

                    try {
                      axios
                        .post(this.UrlApi + "postwa", {
                          url: "group/sendmessage/HRD info",
                          message: pesan,
                        })
                        .then(() => {
                          // console.log(res);
                        });
                    } catch (error) {
                      console.log(error);
                    }
                    localStorage.removeItem("masuk");
                    this.$router.push("/");
                  }
                } else {
                  this.empty = true;
                  Swal.fire({
                    icon: "error",
                    title: "Gagal!",
                    text: res.data.message,
                    showConfirmButton: false,
                    timer: 1500,
                  });
                }
              } else {
                this.$router.push("/");
              }
            })
            .catch((err) => {
              if (err.response) {
                console.log("error response");
              } else if (err.request) {
                console.log("error request");
              } else {
                console.log("error");
              }
              this.empty = true;
            });
        } catch (error) {
          console.log(error);
        }
      } else {
        Swal.fire({
          icon: "warning",
          // title: "Himbauan!",
          text: "Silahkan ulangi isi data kegiatan!",
          showConfirmButton: false,
          timer: 1500,
        });
        this.kegiatan = null;
      }
    },
  },
  created() {
    try {
      axios
        .post(
          this.UrlApi + "listabsensi/detil",
          {
            autono: this.uppercase,
            id_peg: localStorage.getItem("id"),
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.empty = res.data.data.length > 0 ? false : true;
            this.dataView = res.data.data;
            this.selectedSite = res.data.data[0].id_entitas;
            this.siteLainya = res.data.data[0].note;
            // console.log(this.selectedSite);
          } else {
            this.empty = true;
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
          this.empty = true;
        });
    } catch (error) {
      console.log(error);
    }

    try {
      axios
        .post(
          this.UrlApi + "listabsensi/riwayat",
          {
            autono: this.uppercase,
            id_peg: localStorage.getItem("id"),
          },
          { headers: headers }
        )
        .then((res) => {
          if (res.data.success && res.data.success !== null) {
            this.empty = res.data.data.length > 0 ? false : true;
            // this.dataView = res.data.data;
            this.dataRiwayat = res.data.data;
          } else {
            this.empty = true;
            Swal.fire({
              icon: "error",
              title: "Gagal!",
              text: res.data.message,
              showConfirmButton: false,
              timer: 1500,
            });
          }
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
          this.empty = true;
        });
    } catch (error) {
      console.log(error);
    }

    try {
      axios
        .get(this.UrlApi + "getallsite", { headers: headers })
        .then((res) => {
          this.listSite = res.data.data[0];
        })
        .catch((err) => {
          if (err.response) {
            console.log("error response");
          } else if (err.request) {
            console.log("error request");
          } else {
            console.log("error");
          }
          this.empty = true;
        });
    } catch (error) {
      console.log(error);
    }
  },
};
</script>
