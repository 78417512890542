<template>
  <back-section :menu="nama" />
  <list-data-cuti-pending />
</template>
<script>
import BackSection from "@/components/BackSection.vue";
import ListDataCutiPending from "@/components/ListDataCutiPending.vue";
export default {
  setup() {
    return {
      nama: "List Cuti - Pending",
    };
  },
  components: { BackSection, ListDataCutiPending },
};
</script>
