<template>
  <div class="bg-dark position-absolute h-100 drw" v-if="!drawVisible">
    <div class="sticky-top">
      <div class="d-block">
        <button
          v-on:click="toggle"
          type="button"
          class="btn btn-dark float-end text-white w-100"
        >
          X
        </button>
      </div>
    </div>
    <MenuSide />
  </div>
  <nav class="navbar py-3 position-absolute">
    <button
      v-on:click="toggle"
      type="button"
      class="btn btn-link float-end text-white text-decoration-none"
    >
      <img src="../../public/img/home/menu.png" alt="back" width="30" />
    </button>
    <a href="/faq" class="text-decoration-none mx-3">
      <img src="../../public/img/home/faq.png" alt="guide" width="40" />
    </a>
    <!-- <span class="badge bg-light float-end"> -->
    <!-- </span> -->
  </nav>
</template>
<script>
import MenuSide from "./MenuSide.vue";
// import LoginPage from "./components/LoginPage.vue";

export default {
  data() {
    return {
      drawVisible: true,
    };
  },
  components: {
    MenuSide,
    // LoginPage,
  },
  methods: {
    toggle() {
      this.drawVisible = !this.drawVisible;
    },
  },
};
</script>
