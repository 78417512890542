<template>
  <a href="/list-internal-office" class="text-decoration-none text-dark">
    <div class="card my-4">
      <div class="card-header bg-secondary text-white">
        <h5>Internal Office</h5>
      </div>
      <div class="card-body">Absen masuk sesuai site masing-masing</div>
    </div>
  </a>

  <a href="/list-client-site" class="text-decoration-none text-dark">
    <div class="card my-4">
      <div class="card-header bg-secondary text-white">
        <h5>Client Site</h5>
      </div>
      <div class="card-body">
        Ketika di luar kantor, dan kerja di lingkungan klien (contoh : Mabesad)
      </div>
    </div>
  </a>
  <a href="/list-lainnya" class="text-decoration-none text-dark">
    <div class="card my-4">
      <div class="card-header bg-secondary text-white">
        <h5>Lainnya</h5>
      </div>
      <div class="card-body">Selain opsi diatas</div>
    </div>
  </a>
</template>
<script>
export default {};
</script>
