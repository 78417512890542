<template>
  <nav
    class="navbar py-2 back-btn d-flex justify-content-start"
    :class="{ 'sticky-top': sticky, '': !sticky }"
  >
    <button
      v-on:click="toggle"
      type="button"
      class="btn btn-link float-start text-white text-decoration-none"
    >
      <img src="../../public/img/home/back.png" alt="back" width="30" />
    </button>
    <div class="navbar-brand text-white">{{ menu }}</div>
    <!-- <a class="btn btn-success sisa" v-show="sisa_thn_kemaren < 0">
      Sisa Cuti Tahun {{ backYear }} : {{ Math.abs(sisa_thn_kemaren) }}
    </a>
    <a href="/list-cuti-bersama" class="btn btn-warning cuti" v-show="btn">
      Jumlah Cuti Bersama : {{ cuti_bersama }}
    </a> -->
  </nav>
</template>
<script>
export default {
  props: {
    menu: {
      type: String,
      default: "",
    },
    sticky: {
      type: Boolean,
      default: true,
    },
    btn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cuti_bersama: null,
      sisa_thn_kemaren: 0,
      backYear: null,
    };
  },
  methods: {
    toggle() {
      this.$router.go(-1);
    },
  },
  created() {
    var count = localStorage.getItem("ijin");
    count = JSON.parse(count);
    if (count != null) {
      Object.keys(count).forEach((key) => {
        this.cuti_bersama = count[key].cuti_bersama;
        this.sisa_thn_kemaren = count[key].cuti_tahun_kemarin;
      });
    }

    var date = new Date().getFullYear();
    this.backYear = date - 1;
  },
};
</script>
<style scoped>
.cuti {
  position: absolute;
  right: 15px;
}
.sisa {
  position: absolute;
  right: 230px;
}
</style>
