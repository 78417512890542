<template>
  <back-section :menu="nama" />
  <list-data-cuti-tidak-setuju />
</template>
<script>
import BackSection from "@/components/BackSection.vue";
import ListDataCutiTidakSetuju from "@/components/ListDataCutiTidakSetuju.vue";
export default {
  setup() {
    return {
      nama: "List Cuti Ditolak",
    };
  },
  components: { BackSection, ListDataCutiTidakSetuju },
};
</script>
