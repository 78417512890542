<template>
  <router-view />
</template>
<script>
// const Swal = require("sweetalert2");
// Import the functions you need from the SDKs you need
import firebase from "@/services/firebaseInit";
const messaging = firebase.msg;
firebase.onMessage(messaging, (payload) => {
  console.log("Message received. ", payload);
});
export default {
  methods: {
    askBrowserPermission() {
      // Get registration token. Initially this makes a network call, once retrieved
      // subsequent calls to getToken will return from cache.
      const messaging = firebase.msg;
      firebase
        .getToken(messaging, {
          vapidKey:
            "BMaJXxtfEXR1wb_TRadXe923ignE65pwf44mEk0gp-USzkuOz8Etacgo16JQCqL3SrtaCcLHOEpSgqUdB7SY3TU",
        })
        .then((currentToken) => {
          if (currentToken) {
            // Send the token to your server and update the UI if necessary
            // ...
            console.log("Token is ready");
            if (currentToken != "") {
              localStorage.setItem("firebaseToken", currentToken);
            }
          } else {
            // Show permission request UI
            console.log(
              "No registration token available. Request permission to generate one."
            );
            // ...
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // ...
        });
    },
    handlePermission() {
      navigator.permissions
        .query({ name: "notifications" })
        .then(this.permissionQuery)
        .catch("error");
    },
    permissionQuery(result) {
      var newPrompt;

      if (result.state == "granted") {
        // notifications allowed, go wild
        this.askBrowserPermission();
      } else if (result.state == "prompt") {
        // we can ask the user
        newPrompt = Notification.requestPermission();
      } else if (result.state == "denied") {
        // notifications were disabled
      }

      result.onchange = () => {
        this.askBrowserPermission();
      };

      return newPrompt || result;
    },
  },
  created() {
    let userAgentString = navigator.userAgent;

    let chromeAgent = userAgentString.indexOf("Chrome") > -1;
    // eslint-disable-next-line no-unused-vars
    let IExplorerAgent =
      userAgentString.indexOf("MSIE") > -1 ||
      userAgentString.indexOf("rv:") > -1;
    // eslint-disable-next-line no-unused-vars
    let firefoxAgent = userAgentString.indexOf("Firefox") > -1;

    let safariAgent = userAgentString.indexOf("Safari") > -1;

    if (chromeAgent && safariAgent) safariAgent = false;

    let operaAgent = userAgentString.indexOf("OP") > -1;

    if (chromeAgent && operaAgent) chromeAgent = false;

    // if (safariAgent) {
    // Swal.fire({
    //   icon: "warning",
    //   title: "Info",
    //   text: "Saat ini sistem kami belum mendukung penggunaan Safari Browser, silahkan gunakan browser lain untuk mengakses sistem ini",
    //   showConfirmButton: true,
    //   // timer: 3000,
    // });
    // }

    if (!("Notification" in window)) {
      // alert("This browser does not support desktop notification");
    } else {
      this.handlePermission();
      // Notification.requestPermission().then((permission) => {
      //   console.log(permission);
      //   if (permission == "granted") {
      //     console.log("Notification permission granted");
      //   } else {
      //     Swal.fire({
      //       icon: "warning",
      //       title: "Info",
      //       text: "Aplikasi Akan mengirimkan notifikasi, ijinkan ?",
      //       showCancelButton: true,
      //       confirmButtonColor: "#3085d6",
      //       cancelButtonColor: "#d33",
      //       confirmButtonText: "Yes, delete it!",
      //       // timer: 3000,
      //     }).then((result) => {
      //       if (result.isConfirmed) {
      //         Swal.fire("Dijinkan!", "", "success");
      //       }
      //     });
      //   }
      // });
    }
  },
};
</script>
